import { DateTime } from 'luxon'
import { acceptHMRUpdate, defineStore } from 'pinia'
import {
  EmptyInAppointmentSlot,
  EmptyInAppointmentSlotReading,
} from '~/models/appointmentSlot'
import type { ReturnCategory } from '~/models/returnRules'
import type {
  ContainerType,
  EmptyAppointmentSlotsLoadedEvent,
  GETEmptyInAppointmentSlot,
  ShippingLine,
  TerminalName,
} from '~/services/apiClient'
import { AppointmentBookingApi } from '~/services/apiClient'
import { useAppointmentSlotEvents } from '~/compositions/soketi/useAppointmentSlotEvents'

export const useEmptyAppointmentSlotsStore = defineStore(
  'empty-appointment-slots',
  () => {
    const apptSlotEventHelpers = useAppointmentSlotEvents()
    // Refs
    const loadingByKey = ref(new Map<string, boolean>())
    const slotReadings = ref(new Map<string, EmptyInAppointmentSlotReading>())
    const errors = ref(new Map<string, string>())
    // Actions
    const api = new AppointmentBookingApi()
    function makeKey(category: ReturnCategory): string {
      // It's useful if our "internal" key for storage matches our channel name, so that
      // when we get events via channels we can plop them right in their place
      return apptSlotEventHelpers.getAppointmentSlotChannelName(
        category.terminal,
        category.shippingLine,
        category.containerType
      )
    }
    function getSlotReading(
      category: ReturnCategory
    ): EmptyInAppointmentSlotReading | undefined {
      const key = makeKey(category)
      return slotReadings.value.get(key)
    }
    function getErrors(category: ReturnCategory) {
      return errors.value.get(makeKey(category))
    }

    function isLoading(category: ReturnCategory) {
      const key = makeKey(category)
      return loadingByKey.value.get(key) ?? false
    }
    function load({
      terminal,
      shippingLine,
      containerType,
      containerNumber,
      refresh = false,
    }: {
      terminal: TerminalName
      shippingLine: ShippingLine
      containerType: ContainerType
      containerNumber?: string
      refresh?: boolean
    }) {
      const key = makeKey({ shippingLine, containerType, terminal })
      loadingByKey.value.set(key, true)
      slotReadings.value.delete(key)
      api
        .getAvailableEmptyAppointmentSlotsAppointmentsAvailabilityEmptyTerminalGet(
          terminal,
          shippingLine,
          containerType,
          containerNumber,
          refresh,
          { validateStatus: (status) => [200, 404, 409, 500].includes(status) }
        )
        .then((resp) => {
          if (resp.status === 200) {
            const respSlots = mapAndExcludePassedSlots(
              resp.data.slots,
              resp.data.observed,
              resp.data.shipping_line
            )
            slotReadings.value.set(
              key,
              new EmptyInAppointmentSlotReading(respSlots, resp.data.observed)
            )
          } else {
            console.error(resp)
            if (resp.data) {
              if (Object.hasOwn(resp.data, 'message')) {
                // @ts-expect-error
                const errorMessage = resp.data.message
                errors.value.set(key, errorMessage)
              }
            }
          }
        })
        .finally(() => {
          loadingByKey.value.set(key, false)
        })
    }
    function handleEvent(event: EmptyAppointmentSlotsLoadedEvent) {
      const terminal = event.terminal
      const shippingLine = event.shipping_line
      const containerType = event.container_type
      // How to calculate the key? The problem is that the shipping line or container
      // type might be null
      const eventSlots = mapAndExcludePassedSlots(
        event.slots,
        event.observed,
        event.shipping_line
      )
      const reading = new EmptyInAppointmentSlotReading(
        eventSlots,
        event.observed
      )
      const key = apptSlotEventHelpers.getAppointmentSlotChannelName(
        terminal,
        shippingLine,
        containerType
      )
      const observed = DateTime.fromISO(event.observed)
      const observedAgoMs = DateTime.now().diff(observed).as('milliseconds')
      const previousReading = slotReadings.value.get(key)
      const descriptionOfTimeSinceLastReading = previousReading
        ? previousReading.observed.toRelative()
        : 'never'
      // eslint-disable-next-line no-console
      console.log(
        `Received empty slots event for ${event.terminal}-${
          event.shipping_line
        }-${
          event.container_type
        }, observed ${observed.toRelative()} (${observedAgoMs}ms)` +
          `, last reading was ${descriptionOfTimeSinceLastReading}`
      )
      slotReadings.value.set(key, reading)
    }
    function setSlots(
      terminal: TerminalName,
      shippingLine: ShippingLine,
      containerType: ContainerType,
      slots: EmptyInAppointmentSlot[]
    ) {
      const key = makeKey({ terminal, shippingLine, containerType })
      slotReadings.value.set(
        key,
        new EmptyInAppointmentSlotReading(slots, DateTime.now().toISO())
      )
    }
    return { load, isLoading, getSlotReading, getErrors, handleEvent, setSlots }
  }
)

function mapAndExcludePassedSlots(
  slots: GETEmptyInAppointmentSlot[],
  group_observed: string,
  shipping_line: ShippingLine | undefined
) {
  const now = DateTime.now()
  const mappedSlots = slots.map(
    (slot) => new EmptyInAppointmentSlot(slot, group_observed, shipping_line)
  )

  return mappedSlots.filter((slot) => {
    if (slot.window_end) {
      return slot.window_end > now
    } else {
      return slot.window_start.plus({ hours: 1 }) > now
    }
  })
}
if (import.meta.hot) {
  import.meta.hot.accept(
    acceptHMRUpdate(useEmptyAppointmentSlotsStore, import.meta.hot)
  )
}
