import type { ParsedHold } from '~/services/apiClient'
import { HoldType } from '~/services/apiClient'

export function getHoldDescription(hold: ParsedHold): string {
  // Having "Hold" in permit to transfer is confusing for users as it is not
  // really a hold, as in it does not really block things (assuming you are
  // bonded?).
  // Would change in backend but that would cause backwards compatibility issues.
  if (hold.type === HoldType.PermitToTransferHold) {
    return 'Permit to Transfer'
  }
  return hold.type || hold.raw_type
}
