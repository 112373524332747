<script lang="ts" setup></script>

<template>
  <div class="flex">
    <!-- Left Pane -->
    <div class="mr-3px basis-1/2" style="flex: 1 1 50%">
      <slot name="left" />
    </div>
    <!-- Right Pane -->
    <!-- The `width: 0px` ensures we don't exceed the size of the container -->
    <div class="ml-3px basis-1/2" style="min-height: 20vh; width: 0px">
      <slot name="right" />
    </div>
  </div>
</template>
