<script setup lang="ts">
import { ArrowDown } from '@element-plus/icons-vue'
import { computed, onBeforeUnmount } from 'vue'
import { ElFormItem } from 'element-plus'
import TerminalSelector from './TerminalSelector.vue'
import type { AppointmentFilters, MoveTypeFilter } from './types'
import MoveTypeFilterSelector from './MoveTypeFilterSelector.vue'
import StatusSelector from './StatusSelector.vue'
import TruckSelector from '~/components/inputs/TruckSelector.vue'
import ContainersSearch from '~/components/inputs/ContainersSearch.vue'
import CustomerSelector from '~/components/inputs/CustomerSelector.vue'
import type {
  AppointmentStatus,
  ContainerType,
  ShippingLine,
  TerminalName,
} from '~/services/apiClient'
import type { Truck } from '~/models/trucks'
import { useUserStore } from '~/stores/user'
import ShippingLineSelector from '~/components/inputs/ShippingLineSelector.vue'
import ContainerTypeSelector from '~/components/inputs/ContainerTypeSelector.vue'
import { useUsersStore } from '~/stores/users'

const props = withDefaults(
  defineProps<{
    modelValue: AppointmentFilters
    showOnlyLaTerminals?: boolean
    showCustomerFilter?: boolean
  }>(),
  { showCustomerFilter: false }
)

const emit = defineEmits<{
  (event: 'update:modelValue', filters: AppointmentFilters): void
}>()

const userStore = useUserStore()
const usersStore = useUsersStore()

const selectedTags = computed({
  get: () => props.modelValue.selectedTags || [],
  set: (newTags: string[]) => {
    emit('update:modelValue', {
      ...props.modelValue,
      selectedTags: newTags,
    })
  },
})

function onTerminalChanged(terminals: TerminalName[]) {
  emit('update:modelValue', { ...props.modelValue, terminals })
}
function onTruckChanged(truck: Truck | null) {
  emit('update:modelValue', {
    ...props.modelValue,
    licensePlate: truck?.licensePlate || undefined,
  })
}

function onMoveTypeChanged(moveType: MoveTypeFilter | null) {
  emit('update:modelValue', {
    ...props.modelValue,
    moveType: moveType || undefined,
  })
}

function onContainersChanged(containers: string[]) {
  emit('update:modelValue', {
    ...props.modelValue,
    containers: new Set(containers),
  })
}

function onCustomerChanged(customerId: number | undefined) {
  emit('update:modelValue', {
    ...props.modelValue,
    customerId: customerId || undefined,
  })
}

function onShippingLineChanged(shippingLine: string | undefined) {
  emit('update:modelValue', {
    ...props.modelValue,
    shippingLine: (shippingLine as ShippingLine) || undefined,
  })
}

function onContainerTypeChanged(containerType: string | undefined) {
  emit('update:modelValue', {
    ...props.modelValue,
    containerType: containerType as ContainerType | undefined,
  })
}

function onAssignedUserChanged(assignedUsers: number[]) {
  emit('update:modelValue', { ...props.modelValue, assignedUsers })
}

function clearSelectedTags() {
  emit('update:modelValue', { ...props.modelValue, selectedTags: [] })
}

function clearSelectedShippingLine() {
  emit('update:modelValue', { ...props.modelValue, shippingLine: undefined })
}

function clearSelectedContainerType() {
  emit('update:modelValue', { ...props.modelValue, containerType: undefined })
}

function onStatusesChanged(statuses: AppointmentStatus[]) {
  emit('update:modelValue', { ...props.modelValue, statuses })
}

const defaultFilters: AppointmentFilters = {
  containers: new Set(),
  moveType: undefined,
  terminals: [],
  statuses: [],
  licensePlate: undefined,
  shippingLine: undefined,
  containerType: undefined,
  customerId: undefined,
  selectedTags: [],
  assignedUsers: [],
}

const getUserFullName = (id: number) => {
  const user = usersStore.users.find((u) => u.id === id)
  return user ? `${user.first_name} ${user.last_name}` : 'Unknown'
}

const removeAssignedUser = (idToRemove: number) => {
  const updated = {
    ...props.modelValue,
    assignedUsers: props.modelValue.assignedUsers.filter(
      (id) => id !== idToRemove
    ),
  }
  emit('update:modelValue', updated)
}

onBeforeUnmount(() => {
  emit('update:modelValue', { ...defaultFilters })
})
</script>

<template>
  <div class="appointment-filters">
    <ElForm label-position="top" :inline="true" size="default">
      <ElFormItem>
        <ContainersSearch
          :model-value="Array.from(modelValue.containers)"
          @update:model-value="onContainersChanged"
        />
      </ElFormItem>
      <ElFormItem>
        <MoveTypeFilterSelector
          :model-value="modelValue.moveType"
          @update:model-value="onMoveTypeChanged"
        />
      </ElFormItem>
      <ElFormItem>
        <TerminalSelector
          :model-value="modelValue.terminals"
          :show-only-la-terminals="props.showOnlyLaTerminals"
          style="width: 120px"
          @update:model-value="onTerminalChanged"
        />
      </ElFormItem>
      <ElFormItem>
        <StatusSelector
          :model-value="modelValue.statuses"
          @update:model-value="onStatusesChanged"
        />
      </ElFormItem>
    </ElForm>
    <ElForm label-position="top" :inline="true" size="default" class="mt-2">
      <ElFormItem class="-mt-1px">
        <TruckSelector
          :license-plate="modelValue.licensePlate || ''"
          :load-usage-counts="false"
          :narrow-width="false"
          @update:model-value="onTruckChanged"
        />
      </ElFormItem>
      <ElFormItem v-if="!userStore.isSubCarrier">
        <CustomerSelector
          v-if="props.showCustomerFilter"
          v-tooltip="'View appointments for a specific customer'"
          :model-value="modelValue.customerId"
          style="width: 150px"
          class="dropdown-filter"
          size="default"
          @update:model-value="onCustomerChanged"
        />
      </ElFormItem>
      <ElFormItem>
        <el-popover
          :width="440"
          trigger="click"
          placement="bottom"
          :persistent="false"
          :hide-after="0"
        >
          <template #reference>
            <el-button
              :class="{ 'is-active': modelValue.selectedTags.length > 0 }"
              class="ml-2"
              size="default"
            >
              <i-mdi:filter-outline class="align-text-top h-5 w-5 mr-1" />
              More Filters
              <el-icon class="el-icon--right"><ArrowDown /></el-icon>
            </el-button>
          </template>
          <ElFormItem label="Tags:">
            <template #label>
              <i-mdi:tag class="align-middle" height="20" />
              Has (all) Tags:
            </template>
            <TagsSelector v-model="selectedTags" :clearable="true" />
          </ElFormItem>
          <ElForm
            label-position="top"
            style="display: grid; grid-template-columns: 1fr 1fr; gap: 10px"
          >
            <ElFormItem label="Shipping line:" prop="shipping_line">
              <template #label>
                <i-mdi:flag class="align-middle" height="20" />
                Shipping line:
              </template>
              <ShippingLineSelector
                :model-value="modelValue.shippingLine"
                :clearable="true"
                placeholder="Select shipping line"
                @update:model-value="onShippingLineChanged"
              />
            </ElFormItem>
            <ElFormItem label="Container type:" prop="container_type">
              <template #label>
                <EmptyContainerIcon class="align-text-top" />
                Container type:
              </template>
              <ContainerTypeSelector
                :clearable="true"
                :model-value="modelValue.containerType"
                placeholder="Select container type"
                @update:model-value="onContainerTypeChanged"
              />
            </ElFormItem>
          </ElForm>
          <ElFormItem label="Assigned User:" prop="assigned_user">
            <template #label>
              <i-ant-design:user-outlined class="align-text-top" />
              Assigned User:
            </template>
            <UserSelector
              :clearable="true"
              :model-value="modelValue.assignedUsers"
              @update:model-value="onAssignedUserChanged"
            />
          </ElFormItem>
        </el-popover>
      </ElFormItem>
      <clearFilterButton
        v-if="modelValue.selectedTags.length"
        size="default"
        @click="clearSelectedTags"
      >
        {{ modelValue.selectedTags.join(', ') }}
      </clearFilterButton>
      <clearFilterButton
        v-if="modelValue.shippingLine"
        size="default"
        @click="clearSelectedShippingLine"
      >
        {{ modelValue.shippingLine }}
      </clearFilterButton>
      <clearFilterButton
        v-if="modelValue.containerType"
        size="default"
        @click="clearSelectedContainerType"
      >
        {{ modelValue.containerType }}
      </clearFilterButton>
      <clearFilterButton
        v-for="userId in modelValue.assignedUsers"
        :key="userId"
        size="default"
        @click="removeAssignedUser(userId)"
      >
        {{ getUserFullName(userId) }}
      </clearFilterButton>
    </ElForm>
  </div>
</template>

<style lang="scss">
.appointment-filters {
  .el-form-item {
    margin-right: 10px;
    margin-bottom: 10px;
  }
  .el-form-item:last-child {
    margin-right: 0;
  }
}
</style>
