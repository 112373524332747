import { DateTime } from 'luxon'
import { faker } from '@faker-js/faker/locale/en'
import startCase from 'lodash-es/startCase'
import type { Appointment } from './appointments'
import { GateTransaction } from './gateTransaction'
import { ImportStatus } from './importStatus'
import { LineImportStatus } from './lineImportStatus'
import { CleanTruckFee, PierPass } from './pierPass'
import type { ContainerStatusFilter } from '~/constants'
import {
  CONTAINER_DISPLAY_STATES_BY_STATE,
  SHIPPERS_TERMINALS,
  TERMINAL_NOT_REQUIRING_APPOINTMENTS,
  getTerminalLabel,
} from '~/constants'
import type {
  AutoBookRequestStatus,
  ContainerType,
  GETContainer,
  GETContainerBasic,
  SavedVesselForContainer,
  SimpleUser,
} from '~/services/apiClient'

import {
  AppointmentStatus,
  ContainerWatchState,
  HoldType,
  ImportLocation,
  ShippingLine,
  TerminalName,
} from '~/services/apiClient'
import {
  formatDTMilitary,
  parseContainerNumbers,
  shortenContainerType,
} from '~/utils'
import { parseAppointments } from '~/stores/appointments'
import type { ContainerFilters } from '~/compositions/containerFilters'
import { shiftStartFromTime } from '~/logic/shifts'
import { getHoldDescription } from '~/utils/holdUtils'

export interface ContainerBadgeInfo {
  message: string
  type: 'info' | 'warning' | 'danger'
  tooltip?: string
  link?: string
}

export type ContainerState =
  | 'Import Tracking'
  | 'Ready for Appointment'
  | 'Appointment'
  | 'Out-Gated'
  | 'In-Gated'

export interface Action {
  label: 'Booking' | 'Booking Failed' | 'Just Booked'
  status: 'success' | 'warning' | 'danger'
  tooltip: string
}

export class BasicContainer {
  number: string
  container_type?: ContainerType
  shipping_line?: ShippingLine
  last_related_terminal?: TerminalName
  cycle_state: ContainerWatchState
  import_out_gated_time?: DateTime
  customer_id?: number
  customer_name?: string
  tags: string[]

  constructor(container: GETContainerBasic, demoMode: boolean) {
    this.number = container.container_number
    this.container_type = container.container_type
    this.shipping_line = container.shipping_line
    this.last_related_terminal = container.last_related_terminal
    this.cycle_state = container.cycle_state
    this.import_out_gated_time = container.import_out_gated_time
      ? DateTime.fromISO(container.import_out_gated_time)
      : undefined
    if (container.customer) {
      this.customer_id = container.customer.id
      if (demoMode) {
        this.customer_name = faker.company.name()
      } else {
        this.customer_name = container.customer.name
      }
    }
    this.tags = container.tags
  }
}
function parseDT(dt: string | undefined): DateTime | undefined {
  if (dt) {
    return DateTime.fromISO(dt)
  }
}
export class Container {
  number: string
  tags: string[]
  terminal: TerminalName | null
  raw_data: object
  line_import_status: LineImportStatus | null
  import_status: ImportStatus | null
  import_statuses: ImportStatus[]
  pier_pass: PierPass | null = null
  clean_truck_fee: CleanTruckFee | null = null
  latest_appointment: Appointment | null
  other_appointment_counts_by_status: Map<AppointmentStatus, number>
  inbound_gate_transaction: GateTransaction | null
  outbound_gate_transaction: GateTransaction | null
  cycle_state: ContainerWatchState
  stateDescription: string
  status?: ContainerStatusFilter
  master_bill_of_lading?: string
  customer_id?: number
  customer_name: string
  line: ShippingLine | undefined
  type: ContainerType | undefined
  raw_type: string | undefined
  raw_line: string | undefined
  added_by_user?: SimpleUser
  assigned_users: SimpleUser[]
  auto_book_on: boolean
  auto_book_ignoring_capacity: boolean
  auto_book_request_status?: AutoBookRequestStatus
  watched_time: DateTime
  discharged_time?: DateTime
  import_appointment_time?: DateTime
  import_out_gated_time?: DateTime
  empty_appointment_time?: DateTime
  empty_in_gated_time?: DateTime
  last_free_day?: DateTime | null
  matched_vessel: SavedVesselForContainer | undefined
  raw_vessel: string | undefined
  vessel_name: string | undefined
  // Derived fields
  is_in_on_vessel_state: boolean
  is_in_unavailable_state: boolean
  is_in_import_booking_state: boolean
  is_in_import_booked_state: boolean
  is_in_booked_state: boolean
  is_in_out_gated_state: boolean
  is_in_empty_booked_state: boolean
  is_in_empty_returned_state: boolean
  auto_booking_applies: boolean
  import_tracking_applies: boolean
  import_booking_applies: boolean
  shouldHaveLFD: boolean
  badges: ContainerBadgeInfo[]
  type_short: string | undefined
  out_gated_days: number | null
  booked_appointment: Appointment | null
  has_active_appointment: boolean
  scheduled_shift_name: string | null
  scheduledShiftStart: DateTime | null
  terminal_label: string
  tms_reference: string | null
  // This is to keep track of when the frontend receives container updates and to update
  // views appropriately.
  frontendVersion: number
  key: string
  rowHeight: number

  constructor(container: GETContainer, demoMode: boolean) {
    this.number = container.container_number
    this.tags = container.tags
    this.terminal = container.last_related_terminal || null
    this.raw_data = container
    this.line_import_status = container.line_import_status
      ? new LineImportStatus(container.line_import_status)
      : null
    this.import_statuses = container.import_statuses.map(
      (import_status) => new ImportStatus(import_status)
    )
    this.import_status =
      this.import_statuses.length > 0 ? this.import_statuses[0] : null
    this.line = container.shipping_line
    this.type = container.container_type
    for (const importStatus of this.import_statuses) {
      this.line = this.line ?? importStatus.parsed_shipping_line
      this.raw_line = importStatus.shipping_line
      this.type = this.type ?? importStatus.parsed_container_type
      this.raw_type = importStatus.container_type
    }
    if (container.pier_pass) {
      this.pier_pass = new PierPass(container.pier_pass)
    }
    if (container.clean_truck_fee) {
      this.clean_truck_fee = new CleanTruckFee(container.clean_truck_fee)
    }
    this.latest_appointment = container.latest_appointment
      ? parseAppointments([container.latest_appointment], demoMode)[0]
      : null
    this.other_appointment_counts_by_status = new Map(
      container.other_appointment_counts.map((count) => [
        count.status,
        count.count,
      ])
    )
    this.inbound_gate_transaction = container.inbound_gate_transaction
      ? new GateTransaction(container.inbound_gate_transaction)
      : null
    this.outbound_gate_transaction = container.outbound_gate_transaction
      ? new GateTransaction(container.outbound_gate_transaction)
      : null
    this.cycle_state = container.cycle_state
    this.status = CONTAINER_DISPLAY_STATES_BY_STATE.get(this.cycle_state)
    if (this.cycle_state === ContainerWatchState.ImportEmptyApptBooked) {
      // Kinda hacky by we want to be more specific for this case and not just show
      // "Appointed"
      this.stateDescription = 'Empty Appointed'
    } else if (
      this.cycle_state === ContainerWatchState.ImportPickedUp &&
      this.import_status &&
      this.import_status.parsed_location === ImportLocation.Rail
    ) {
      this.stateDescription = 'On Rail'
    } else {
      this.stateDescription = this.status?.label ?? startCase(this.cycle_state)
    }

    this.master_bill_of_lading = container.master_bl
    this.tms_reference = container.tms_reference || null
    if (demoMode) {
      this.customer_name = faker.company.name()
      this.added_by_user = {
        id: 1,
        first_name: faker.person.firstName(),
        last_name: faker.person.lastName(),
      }
      this.assigned_users = [
        {
          id: 1,
          first_name: faker.person.firstName(),
          last_name: faker.person.lastName(),
        },
      ]
    } else {
      this.added_by_user = container.added_by_user
      this.customer_name = container.customer?.name
        ? container.customer.name
        : 'No Customer'
      this.customer_id = container.customer?.id
      this.assigned_users = container.assigned_users
    }
    this.matched_vessel = container.matched_vessel
    this.raw_vessel = container.vessel
    this.vessel_name = container.matched_vessel?.name || container.vessel
    this.auto_book_on = container.auto_book_on
    this.auto_book_ignoring_capacity = container.auto_book_ignoring_capacity
    this.auto_book_request_status = container.auto_book_request_status
    this.frontendVersion = 0
    this.key = this._updateKey()
    this.watched_time = DateTime.fromISO(container.watched_time)
    this.discharged_time = parseDT(container.discharged_time)
    this.import_appointment_time = parseDT(container.import_appointment_time)
    this.import_out_gated_time = parseDT(container.import_out_gated_time)
    this.empty_appointment_time = parseDT(container.empty_appointment_time)
    this.empty_in_gated_time = parseDT(container.empty_in_gated_time)
    this.last_free_day =
      parseDT(container.last_free_date) ||
      parseDT(container.import_status?.last_free_date)
    this.is_in_on_vessel_state =
      this.cycle_state === ContainerWatchState.ImportTrackingOnVessel
    this.is_in_unavailable_state =
      this.cycle_state === ContainerWatchState.ImportTracking
    this.is_in_import_booking_state =
      this.cycle_state === ContainerWatchState.ImportApptBooking
    this.is_in_import_booked_state =
      this.cycle_state === ContainerWatchState.ImportApptBooked
    this.is_in_out_gated_state =
      container.cycle_state === ContainerWatchState.ImportPickedUp
    this.is_in_empty_booked_state =
      this.cycle_state === ContainerWatchState.ImportEmptyApptBooked
    this.is_in_empty_returned_state =
      this.cycle_state === ContainerWatchState.ImportEmptyReturned

    // General booked state (empty or import)
    this.is_in_booked_state =
      this.is_in_import_booked_state || this.is_in_empty_booked_state
    this.auto_booking_applies =
      this.cycle_state === ContainerWatchState.ImportTracking ||
      this.cycle_state === ContainerWatchState.ImportTrackingOnVessel
    this.import_booking_applies =
      this.cycle_state === ContainerWatchState.ImportApptBooked ||
      this.is_in_import_booking_state

    this.import_tracking_applies =
      this.import_booking_applies ||
      this.cycle_state === ContainerWatchState.ImportTracking ||
      this.cycle_state === ContainerWatchState.ImportTrackingOnVessel
    const LFD_STATES: ContainerWatchState[] = [
      ContainerWatchState.ImportTracking,
      ContainerWatchState.ImportApptBooking,
      ContainerWatchState.ImportApptBooked,
    ]
    this.shouldHaveLFD = LFD_STATES.includes(this.cycle_state)
    this.badges = this.calcBadges()
    this.type_short = shortenContainerType(this.type)
    this.out_gated_days = this.calcOutGatedDays()
    this.booked_appointment = this.calcBookedAppointment()
    this.has_active_appointment = Boolean(this.booked_appointment)
    this.scheduled_shift_name = this.calcScheduledShiftName()
    this.scheduledShiftStart = this.booked_appointment
      ? shiftStartFromTime(this.booked_appointment.window_start)
      : null
    this.terminal_label = this.calcTerminalLabel()
    this.rowHeight = this.calcRowHeight()
  }

  get showMiddleRow(): boolean {
    return Boolean(
      this.badges.length > 0 ||
        this.is_in_empty_returned_state ||
        this.is_in_out_gated_state ||
        this.booked_appointment
    )
  }

  get lfdIsRelevant(): boolean {
    return (
      // On vessel would only be relevant if they had for some reason set a LFD, which
      // normally they do not
      (this.is_in_on_vessel_state && !!this.last_free_day) ||
      this.is_in_unavailable_state ||
      this.is_in_import_booking_state ||
      this.is_in_import_booked_state
    )
  }

  get lfdInfo(): string | undefined {
    // find other lfd's in other_lfds
    const lfdDescriptions: string[] = []
    if (this.uniqueDateCount <= 1) {
      return undefined
    }
    if (this.line_import_status && this.line_import_status.last_free_day) {
      let desc = this.line_import_status.parsed_shipping_line
        ? this.line_import_status.parsed_shipping_line
        : ''
      desc = desc + '_line_last_free_day'
      lfdDescriptions.push(
        `${this.prettifyLFDDescription(desc)}: ${this.line_import_status.last_free_day.toFormat('yyyy-MM-dd')}`
      )
    }
    if (this.import_status) {
      for (const lfd of this.import_status.other_last_free_dates) {
        lfdDescriptions.push(
          `${this.prettifyLFDDescription(lfd.description)}: ${lfd.date}`
        )
      }
    }
    // join the lfd descriptions with a comma
    return lfdDescriptions.join(', ')
  }

  get mainLfdSource(): string | undefined {
    const sourceText = 'Source: '
    if (this.last_free_day) {
      if (
        this.line_import_status &&
        this.line_import_status.last_free_day &&
        this.line_import_status.last_free_day.toFormat('yyyy-MM-dd') ===
          this.last_free_day.toFormat('yyyy-MM-dd')
      ) {
        return sourceText + this.line_import_status.sourceLabel
      }
      if (
        this.import_status &&
        this.import_status.last_free_day &&
        this.import_status.last_free_day.toFormat('yyyy-MM-dd') ===
          this.last_free_day.toFormat('yyyy-MM-dd')
      ) {
        return sourceText + this.import_status.sourceLabel
      }
    }
    return undefined
  }

  get uniqueDateCount(): number {
    const uniqueDates = new Set<string>()
    if (this.line_import_status && this.line_import_status.last_free_day) {
      uniqueDates.add(
        this.line_import_status.last_free_day.toFormat('yyyy-MM-dd')
      )
    }
    if (this.last_free_day != null) {
      uniqueDates.add(this.last_free_day.toFormat('yyyy-MM-dd'))
    }
    if (this.import_status) {
      if (this.import_status.last_free_day != null) {
        uniqueDates.add(this.import_status.last_free_day.toFormat('yyyy-MM-dd'))
      }
      for (const lfd of this.import_status.other_last_free_dates) {
        let date = lfd.date.trim()
        date.replaceAll('/', '-')
        if (date.length === 5 && this.import_status.last_free_day) {
          date = this.import_status.last_free_day.year + '-' + date
        } else if (date.length === 5) {
          continue // skip this as it isn't formatted and we can't determine the year
        }
        uniqueDates.add(date)
      }
    }
    return uniqueDates.size
  }

  prettifyLFDDescription(desc: string) {
    desc = desc.replaceAll('_', ' ')
    return desc
      .split(' ')
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
      .join(' ')
  }

  // Just an alias
  get container_number(): string {
    return this.number
  }

  get container_type(): ContainerType | null {
    return this.type ?? null
  }

  calcRowHeight(): number {
    let height = 60
    if (this.showMiddleRow) {
      height += 24
    }
    return height
  }

  calcOutGatedDays(): number | null {
    if (this.import_out_gated_time) {
      const start = this.import_out_gated_time.startOf('day')
      let end
      if (this.empty_in_gated_time) {
        end = this.empty_in_gated_time
      } else {
        end = DateTime.now()
      }
      // I've been advised that what matters is the "real" calendar day of the gate
      // transaction, not the "shift date"
      end = end.startOf('day')
      const days = end.diff(start).as('days')

      // We add one because both start and end calendars days are included in the count
      return Math.floor(days) + 1
    }
    return null
  }

  calcBookedAppointment(): Appointment | null {
    if (
      this.latest_appointment &&
      this.is_in_booked_state &&
      (this.latest_appointment.status === AppointmentStatus.Scheduled ||
        this.latest_appointment.status === AppointmentStatus.InProgress ||
        this.latest_appointment.status === AppointmentStatus.Completed)
    ) {
      return this.latest_appointment
    } else {
      return null
    }
  }

  calcScheduledShiftName(): string | null {
    if (this.booked_appointment) {
      return this.booked_appointment.scheduledShift
    } else {
      return null
    }
  }

  calcTerminalLabel(): string {
    if (this.terminal) {
      return getTerminalLabel(this.terminal)
    } else {
      return '-'
    }
  }

  calcBadges(): ContainerBadgeInfo[] {
    const badges: ContainerBadgeInfo[] = this.getTagBadges()
    const maybeBadges = [
      this.getStaleAppointmentBadges(),
      this.getStaleImportStatusesBadges(),
      this.getAPMDualBadge(),
      this.getUpcomingAppointmentBadge(),
      this.getClosedAreaBadge(),
      this.getReadyForPickupBadge(),
      this.getMissedAppointmentBadge(),
      this.getAppointmentNotRequiredBadge(),
      this.getShippersBadge(),
      this.getAPMNoBolBadge(),
      this.getBookedOffDrayDogBadge(),
      this.getLBCTLineLFDWarningBadge(),
      this.getStaleScheduledAppointmentBadge(),
    ]
    for (const maybeBadge of maybeBadges) {
      if (maybeBadge) {
        badges.push(maybeBadge)
      }
    }
    badges.push(...this.getPierPassBadges())
    if (this.import_status) {
      for (const hold of this.import_status.parsed_holds) {
        // Skip TMF holds as they are now handled in getPierPassBadges()
        if (hold.type === HoldType.TmfHold) {
          continue
        }
        // Skip CTF holds if they are handled by clean_truck_fee
        if (
          hold.type === HoldType.CtfHold &&
          this.clean_truck_fee !== null &&
          this.clean_truck_fee.has_hold === true
        ) {
          continue
        }
        badges.push({
          message: getHoldDescription(hold),
          type: 'warning',
          tooltip: `Raw: ${hold.raw_type}. Found on ${this.import_status.sourceLabel}`,
        })
      }
      const demurrageWarning = this.getImportStatusDemurrageBadges()
      if (demurrageWarning) {
        badges.push(demurrageWarning)
      }
    }
    if (this.is_in_empty_returned_state) {
      badges.push(...this.getTerminatedBadges())
    }
    return badges
  }

  getStaleImportStatusesBadges(): ContainerBadgeInfo | undefined {
    if (
      this.import_tracking_applies &&
      this.import_status &&
      this.import_status.isStale
    ) {
      return {
        message: `Data fetch failure`,
        type: 'danger',
        tooltip: this.import_status.staleDescription,
      }
    }
  }

  getStaleAppointmentBadges(): ContainerBadgeInfo | undefined {
    if (
      this.is_in_booked_state &&
      this.latest_appointment &&
      this.latest_appointment.isScheduled &&
      this.latest_appointment.isStale
    ) {
      const portalDesc = this.latest_appointment.portal_name
      const lastSeenDesc = formatDTMilitary(
        this.latest_appointment.window_start
      )
      const lastSeenAgoDesc = this.latest_appointment.window_start.toRelative()
      return {
        message: `Appointment not found`,
        type: 'danger',
        tooltip:
          `We last saw this appointment on ${portalDesc} at ${lastSeenDesc} (${lastSeenAgoDesc}) but have not observed it since. ` +
          `You should double check directly with the appointment system, this appointment may have been deleted`,
      }
    }
  }

  getTagBadges(): ContainerBadgeInfo[] {
    const badges: ContainerBadgeInfo[] = []
    this.tags.forEach((tag) => {
      badges.push({
        message: tag,
        type: 'info',
        tooltip: `Tag: ${tag}`,
      })
    })
    return badges
  }

  getImportStatusDemurrageBadges(): ContainerBadgeInfo | undefined {
    if (
      ![
        'import_tracking',
        'import_appt_booking',
        'import_appt_booked',
      ].includes(this.cycle_state) ||
      !this.import_status
    ) {
      return
    }
    if (
      this.import_status.demurrage_fees &&
      this.import_status.demurrage_fees > 0
    ) {
      const demurrageDesc =
        '$' + `${this.import_status.demurrage_fees.toLocaleString()}`
      return {
        message: demurrageDesc,
        type: 'danger',
        tooltip:
          `Found ${demurrageDesc} demurrage charge on ` +
          this.import_status.sourceLabel,
      }
    }
  }

  getAppointmentNotRequiredBadge(): ContainerBadgeInfo | undefined {
    //
    const statusesToWarn: ContainerWatchState[] = [
      ContainerWatchState.ImportApptBooking,
      ContainerWatchState.ImportApptBooked,
      ContainerWatchState.ImportTracking,
      ContainerWatchState.ImportTrackingOnVessel,
    ]
    if (statusesToWarn.includes(this.cycle_state)) {
      if (
        (this.terminal &&
          TERMINAL_NOT_REQUIRING_APPOINTMENTS.has(this.terminal)) ||
        (this.import_status?.extra_data &&
          'repo' in this.import_status.extra_data &&
          this.import_status.extra_data.repo !== null)
      ) {
        return {
          message: `No Appointment Required`,
          type: 'info',
          tooltip: `Container may not require an appointment, check repo.`,
        }
      }
    }
  }

  getShippersBadge(): ContainerBadgeInfo | undefined {
    const statusesToWarn: ContainerWatchState[] = [
      ContainerWatchState.ImportApptBooking,
      ContainerWatchState.ImportApptBooked,
      ContainerWatchState.ImportTracking,
      ContainerWatchState.ImportTrackingOnVessel,
    ]
    // any of the terminals in this.import_statuses
    const terminals = this.import_statuses.map((status) => status.terminal)
    if (statusesToWarn.includes(this.cycle_state)) {
      if (this.terminal && !SHIPPERS_TERMINALS.has(this.terminal)) {
        if (
          (this.import_status?.extra_data &&
            'repo' in this.import_status.extra_data &&
            this.import_status.extra_data.repo !== null) ||
          terminals.some((terminal) => SHIPPERS_TERMINALS.has(terminal))
        ) {
          return {
            message: `Shippers`,
            type: 'info',
            tooltip: `Container may have moved to shippers`,
          }
        }
      }
    }
  }

  getAPMDualBadge(): ContainerBadgeInfo | undefined {
    if (
      this.terminal === TerminalName.Apm &&
      this.cycle_state === ContainerWatchState.ImportApptBooked &&
      this.latest_appointment &&
      this.latest_appointment.linked_appointments.length === 0 &&
      this.latest_appointment.extra_data
    ) {
      if (
        Object.prototype.hasOwnProperty.call(
          this.latest_appointment.extra_data,
          'dual_empty_placeholder'
        )
      ) {
        return {
          message: '✅ Empty Placeholder',
          type: 'info',
          tooltip:
            '"ADD EMPTY DROPOFF" was checked when booking, so there is an empty placeholder appointment and any accepted container type should be able to be added as an empty',
        }
      } else {
        return {
          message: '❌ No Empty Placeholder',
          type: 'info',
          tooltip:
            'There is no empty placeholder on for this appointment, there will have to be an empty appointment available for whatever empty container you would like to add',
        }
      }
    }
  }

  getAPMNoBolBadge(): ContainerBadgeInfo | undefined {
    const statusesToWarn: ContainerWatchState[] = [
      ContainerWatchState.ImportApptBooking,
      ContainerWatchState.ImportApptBooked,
      ContainerWatchState.ImportTracking,
      ContainerWatchState.ImportTrackingOnVessel,
    ]
    if (
      this.terminal === TerminalName.Apm &&
      statusesToWarn.includes(this.cycle_state) &&
      this.import_status &&
      this.import_status.master_bill_of_lading === null
    ) {
      return {
        message: '⛔️ No BOL',
        type: 'warning',
        tooltip:
          'BOL not found but may be required for booking. Please check with the terminal.',
      }
    }
  }

  getLBCTLineLFDWarningBadge(): ContainerBadgeInfo | undefined {
    let link: string | undefined
    let tooltip: string | undefined
    const statusesToWarn: ContainerWatchState[] = [
      ContainerWatchState.ImportApptBooking,
      ContainerWatchState.ImportApptBooked,
      ContainerWatchState.ImportTracking,
    ]
    if (
      this.terminal === TerminalName.Lbct &&
      statusesToWarn.includes(this.cycle_state) &&
      (this.line === ShippingLine.Oolu || this.line === ShippingLine.Oney)
    ) {
      if (this.line_import_status && this.line_import_status.last_free_day) {
        return
      }
      if (this.line === ShippingLine.Oolu) {
        link =
          'https://www.oocl.com/eng/ourservices/eservices/cargotracking/Pages/cargotracking.aspx'
        tooltip = 'Click to view on OOCL'
      } else if (this.line === ShippingLine.Oney) {
        link =
          'https://ecomm.one-line.com/one-ecom/manage-shipment/cargo-tracking?sessLocale=en'
        tooltip = 'Click to view on ONE'
      }
      return {
        message: `CHECK LINE FOR LFD`,
        type: 'danger',
        tooltip,
        link,
      }
    }
  }

  getBookedOffDrayDogBadge(): ContainerBadgeInfo | undefined {
    if (
      (this.cycle_state === ContainerWatchState.ImportApptBooked ||
        this.cycle_state === ContainerWatchState.ImportPickedUp ||
        this.cycle_state === ContainerWatchState.ImportEmptyApptBooked) &&
      this.latest_appointment &&
      !this.latest_appointment.booked_on_dray_dog
    ) {
      return {
        message: `Not booked by DD`,
        type: 'warning',
        tooltip:
          `Appointment booked directly with ${this.latest_appointment.portal_name} (not using Dray Dog).` +
          ` Book via Dray Dog for better tracking`,
      }
    }
  }

  getMissedAppointmentBadge(): ContainerBadgeInfo | undefined {
    if (!this.import_booking_applies) return
    let numMissedAppointments =
      this.other_appointment_counts_by_status.get(AppointmentStatus.Missed) ?? 0
    if (
      this.latest_appointment &&
      this.latest_appointment.status === AppointmentStatus.Missed
    ) {
      numMissedAppointments += 1
    }
    if (numMissedAppointments > 0) {
      return {
        message: `${numMissedAppointments} Missed`,
        type: 'danger',
        tooltip: `${numMissedAppointments} missed appointments`,
      }
    }
  }

  getStaleScheduledAppointmentBadge(): ContainerBadgeInfo | undefined {
    if (!this.is_in_import_booking_state) return
    if (
      this.latest_appointment &&
      this.latest_appointment.status === AppointmentStatus.Scheduled &&
      this.latest_appointment.is_import_out &&
      this.latest_appointment.window_start < DateTime.now() &&
      this.latest_appointment.window_start > DateTime.now().minus({ days: 1 })
    ) {
      const apptStart =
        this.latest_appointment.window_start.toFormat('yyyy-MM-dd HH:mm')
      const refToUse =
        this.latest_appointment.display_terminal_reference ||
        this.latest_appointment.terminal_reference
      return {
        message: `Stale Appt`,
        type: 'danger',
        tooltip:
          `Scheduled appointment ` +
          `${refToUse} with start time ${apptStart} is in the past`,
      }
    }
  }

  getTrapacWarningBadge(): ContainerBadgeInfo | undefined {
    const statusesToWarn: ContainerWatchState[] = [
      ContainerWatchState.ImportApptBooking,
      ContainerWatchState.ImportApptBooked,
      ContainerWatchState.ImportTracking,
      ContainerWatchState.ImportTrackingOnVessel,
    ]
    if (
      this.terminal === TerminalName.Trapac &&
      statusesToWarn.includes(this.cycle_state)
    ) {
      return {
        message: 'Reliability Issues (hover me)',
        type: 'danger',
        tooltip:
          'Trapac data unreliable right now. Consider checking the terminal website',
      }
    }
  }

  getClosedAreaBadge(): ContainerBadgeInfo | undefined {
    if (this.import_status && this.import_status.closed_area === true) {
      let link: string | undefined
      let tooltip = `This container is in a closed area`
      if (this.terminal === TerminalName.Yti) {
        tooltip += `. Check YTI Closed Area schedule to verify (click to view)`
        link = 'https://yti.com/terminal-schedules/closed-vessel-area-schedule/'
      }
      // This case is for TTI Unable To Locate (message: CONTAINER IN UNDEFINED LOCATION)
      if (
        this.import_tracking_applies &&
        this.import_status.ready_for_appointment === false &&
        Object.prototype.hasOwnProperty.call(
          this.import_status.extra_data,
          'availability_reason'
        ) &&
        // @ts-expect-error
        this.import_status.extra_data.availability_reason
      ) {
        const reason: string =
          // @ts-expect-error
          this.import_status.extra_data.availability_reason
        tooltip += `: ${reason}`
      }
      return {
        message: 'Closed Area',
        type: 'danger',
        tooltip,
        link,
      }
    }
  }

  getReadyForPickupBadge(): ContainerBadgeInfo | undefined {
    const statusesToWarn: ContainerWatchState[] = [
      ContainerWatchState.ImportApptBooking,
      ContainerWatchState.ImportApptBooked,
    ]
    if (
      statusesToWarn.includes(this.cycle_state) &&
      this.import_status &&
      this.import_status.available_for_pickup === false
    ) {
      return {
        message: 'Not Ready For Pickup',
        type:
          this.cycle_state === ContainerWatchState.ImportApptBooked
            ? 'danger' // "Not ready for pickup" is DANGEROUS if you have an appointment...
            : 'warning', // ...but not if you don't
        tooltip: `Not ready for pickup`,
      }
    }
  }

  getUpcomingAppointmentBadge(): ContainerBadgeInfo | undefined {
    if (this.booked_appointment) {
      if (
        this.booked_appointment.window_start &&
        this.booked_appointment.window_start < DateTime.now() &&
        this.booked_appointment.status !== 'in_progress'
      ) {
        return {
          message: '🚨 Appointment time reached',
          type: 'danger',
          tooltip:
            'Appointment start time has been reached, cancel if it will be missed!',
        }
      }
    }
  }

  getPierPassBadges(): ContainerBadgeInfo[] {
    const badges: ContainerBadgeInfo[] = []
    if (
      this.cycle_state &&
      !(
        this.cycle_state === ContainerWatchState.ImportTracking ||
        this.cycle_state === ContainerWatchState.ImportTrackingOnVessel ||
        this.cycle_state === ContainerWatchState.ImportApptBooking ||
        this.cycle_state === ContainerWatchState.ImportApptBooked
      )
    ) {
      return badges
    }
    badges.push(...this.getBadgesForFeeType(this.pier_pass, 'TMF'))
    badges.push(...this.getBadgesForFeeType(this.clean_truck_fee, 'CTF'))
    return badges
  }

  getBadgesForFeeType(
    pierPassObject: PierPass | null,
    feeName: string
  ): ContainerBadgeInfo[] {
    const warnings: ContainerBadgeInfo[] = []
    const portalLabel = feeName === 'TMF' ? 'PierPass' : 'PortCheck'

    if (feeName === 'TMF' && this.import_status) {
      const hasPierPassHold = pierPassObject?.has_hold ?? false
      const hasTerminalHold = this.import_status.parsed_holds.some(
        (hold) => hold.type === HoldType.TmfHold
      )

      if (hasPierPassHold && hasTerminalHold) {
        warnings.push({
          message: 'TMF',
          type: 'warning',
          tooltip: `Hold found on Pier Pass and ${this.import_status.sourceLabel}`,
        })
      } else if (hasPierPassHold && !hasTerminalHold) {
        warnings.push({
          message: 'TMF (Pier Pass)',
          type: 'warning',
          tooltip: `Hold cleared on ${this.import_status.sourceLabel}, but still showing in Pier Pass website`,
        })
      } else if (!hasPierPassHold && hasTerminalHold) {
        warnings.push({
          message: `TMF (${getTerminalLabel(this.import_status.terminal)})`,
          type: 'warning',
          tooltip: `Hold cleared on Pier Pass website, but still showing in ${this.import_status.sourceLabel}`,
        })
      }
    } else if (pierPassObject) {
      if (pierPassObject.has_hold) {
        warnings.push({
          message: portalLabel + ': ' + feeName,
          type: 'warning',
          tooltip: `Hold found on ${portalLabel} website`,
        })
      }
      if (pierPassObject.terminals.size > 1) {
        const terminalsDesc = [...pierPassObject.terminals.values()]
          .map(getTerminalLabel)
          .join(', ')
        warnings.push({
          message: portalLabel + ': ' + feeName + ' Multiple Terminals',
          type: 'danger',
          tooltip: `Found on ${feeName} website - pay attention! Terminals: ${terminalsDesc}`,
        })
      }
      if (pierPassObject.terminals.size === 0) {
        warnings.push({
          message: portalLabel + ': ' + feeName + ' No Terminal Found',
          type: 'warning',
          tooltip: `Found on ${feeName} website`,
        })
      }
    } else if (this.import_status && !pierPassObject) {
      warnings.push({
        message: portalLabel + ': Unknown',
        type: 'info',
        tooltip: `${feeName} status is pending/unknown or we could not find this container in the ${portalLabel} system`,
      })
    }
    return warnings
  }

  getTerminatedBadges(): ContainerBadgeInfo[] {
    const badges = [] as ContainerBadgeInfo[]
    if (this.is_in_empty_returned_state) {
      if (this.inbound_gate_transaction) {
        if (this.inbound_gate_transaction.truck_rfid) {
          badges.push({
            message: 'RFID: ' + this.inbound_gate_transaction.truck_rfid,
            type: 'info',
            tooltip: `RFID number of the terminating truck`,
          })
        }
        if (this.inbound_gate_transaction.truck_license_plate) {
          badges.push({
            message:
              'Plate: ' + this.inbound_gate_transaction.truck_license_plate,
            type: 'info',
            tooltip: `License plate of the terminating truck`,
          })
        }
        if (this.inbound_gate_transaction.drivers_license_number) {
          badges.push({
            message:
              'Driver: ' + this.inbound_gate_transaction.drivers_license_number,
            type: 'info',
            tooltip: `Commercial driver's licence number of the terminating driver`,
          })
        }
      }
    }
    return badges
  }

  matchesFilters(filter: ContainerFilters): boolean {
    if (filter.states && !filter.states.includes(this.cycle_state)) return false
    if (filter.terminal && filter.terminal !== this.terminal) return false
    if (filter.container_numbers) {
      const parsedContainerNumbers = parseContainerNumbers(
        filter.container_numbers
      )
      if (!parsedContainerNumbers.includes(this.number)) return false
    }
    // TODO: filter.updated_in_last_minutes, although we would need to update the API
    // to return last_updated in the container model
    if (filter.shipping_lines) {
      if (!this.line) return false
      if (!filter.shipping_lines.includes(this.line)) return false
    }
    if (filter.container_types) {
      if (!this.type) return false
      if (!filter.container_types.includes(this.type)) return false
    }
    const assigned_user_ids = filter.assigned_user_ids
    if (assigned_user_ids) {
      // -1 means filter to unassigned containers (i.e. no assigned users)
      if (assigned_user_ids.includes(-1)) {
        if (this.assigned_users.length > 0) return false
      } else if (
        !this.assigned_users.some((user) => assigned_user_ids.includes(user.id))
      )
        return false
    }
    if (filter.auto_book_off !== undefined) {
      // TODO Fix this criteria to match API
      if (filter.auto_book_off === this.auto_book_on) return false
    }
    if (filter.auto_book_on !== undefined) {
      // TODO Fix this criteria to match API
      if (filter.auto_book_on !== this.auto_book_on) return false
    }
    if (filter.customer_id) {
      if (filter.customer_id !== this.customer_id) return false
    }
    const tags = filter.tags
    if (tags !== undefined) {
      let hasTagMatch = false
      for (const tag of tags) {
        if (this.tags.includes(tag)) {
          hasTagMatch = true
          break
        }
      }
      if (!hasTagMatch) return false
    }
    return true
  }

  incrementFrontendVersion(version?: number): void {
    this.frontendVersion = version ?? 0
    this._updateKey()
  }

  _updateKey(): string {
    this.key = `${this.number}-${this.frontendVersion}`
    return this.key
  }
}
