<script setup lang="ts">
import { Fragment, h } from 'vue'
import Box from './Box.vue'
import ShiftHeaderRow from './ShiftHeaderRow.vue'
import type { RescheduleInfo } from './types'
import { AppointmentTableRow } from './types'
import ActionButtonsCell from './ActionButtonsCell.vue'
import LicensePlateCell from './LicensePlateCell.vue'
import PickupNumberCell from './PickupNumberCell.vue'
import StatusCell from './StatusCell.vue'
import LFDCell from './LFDCell.vue'
import TimeCell from './TimeCell.vue'
import ContainerNumberCell from './ContainerNumberCell.vue'
import TransactionActions from '~/components/dispatch_table/TransactionActions.vue'
import ContainerBadges from '~/components/container_row/ContainerBadges.vue'
import StickyHeaderVirtualTable from '~/components/display/virtual_scrolling/StickyHeaderVirtualTable.vue'
import type {
  CellRendererParams,
  ColumnDef,
} from '~/components/display/virtual_scrolling/types'

import type { ShiftGroup } from '~/models/groupedAppointments'
import { type AppointmentStatus } from '~/services/apiClient'
import { useAppointmentsRefreshRequestsStore } from '~~/stores/appointmentsRefreshRequests'
import { useRescheduling } from '~/compositions/mutations/useRescheduling'

const props = defineProps<{
  height: number
  minWidth: number
  appointmentShiftGroups: ShiftGroup[]
  selectedShift: ShiftGroup | undefined
}>()

const emit = defineEmits({
  firstVisibleShiftChanged: (shiftGroup: ShiftGroup) => true,
})

const boxElement = ref<HTMLElement | null>(null)
const virtualTableRef = ref<{ scrollToHeader: (headerID: string) => void }>()
const appointmentsRefreshRequestsStore = useAppointmentsRefreshRequestsStore()
const rescheduling = useRescheduling()
const hoveredRowKey = ref<string | null>(null)
const rowHeight = 38
const headerHeight = 32
const shiftHeaderHeight = 28
const shiftHeaderHeightPixels = `${shiftHeaderHeight}px`

const rows = computed((): AppointmentTableRow[] => {
  const rows: AppointmentTableRow[] = []

  for (const shiftGroup of props.appointmentShiftGroups) {
    if (shiftGroup.countTotal === 0) continue
    for (const trx of shiftGroup.transactions) {
      rows.push(new AppointmentTableRow(shiftGroup, trx))
    }
  }
  return rows
})

function triggerRefreshAppointments() {
  appointmentsRefreshRequestsStore.triggerRefresh()
}
// set a timer to refresh appointments every 5 minutes
const refreshInterval = setInterval(triggerRefreshAppointments, 20 * 60 * 1000)
onUnmounted(() => {
  clearInterval(refreshInterval)
})

function scrollToShift(shiftGroup: ShiftGroup) {
  virtualTableRef.value?.scrollToHeader(shiftGroup.key)
}

const openRescheduleRowKey = ref<string | null>(null)
function toggleReschedulePopover(rowKey: string) {
  openRescheduleRowKey.value =
    openRescheduleRowKey.value === rowKey ? null : rowKey
}
function closeReschedulePopover() {
  openRescheduleRowKey.value = null
}

defineExpose({
  scrollToShift,
})
type CellParams = CellRendererParams<AppointmentTableRow>

const CONTAINER_COL_WIDTH = 160

const columns: ColumnDef<AppointmentTableRow>[] = [
  {
    minWidth: 150,
    maxWidth: 180,
    flexGrow: 1,
    title: 'TIME',
    cellRenderer: TimeCell,
  },
  {
    minWidth: 88,
    flexGrow: 1,
    title: 'LFD',
    cellRenderer: LFDCell,
  },
  {
    minWidth: 75,
    maxWidth: 75,
    flexGrow: 1,
    title: 'TERM',
    dataKey: 'terminalLabel',
  },
  {
    minWidth: CONTAINER_COL_WIDTH + 5,
    title: 'DROP-OFF',
    cellRenderer: ({ rowData }: CellParams) => {
      return h(ContainerNumberCell, {
        containerInfo: rowData.inboundContainerInfo,
        isDual: !!rowData.outboundContainerInfo,
        transaction: rowData.transaction,
        rowIsHovered: hoveredRowKey.value === rowData.rowKey,
      })
    },
  },
  {
    minWidth: CONTAINER_COL_WIDTH + 30,
    title: 'PICK-UP',
    cellRenderer: ({ rowData }: CellParams) => {
      return h(ContainerNumberCell, {
        containerInfo: rowData.outboundContainerInfo,
        isDual: false,
        transaction: rowData.transaction,
        rowIsHovered: hoveredRowKey.value === rowData.rowKey,
      })
    },
  },
  {
    minWidth: 80,
    title: 'TYPE',
    dataKey: 'moveTypeDescription',
  },
  {
    minWidth: 94,
    title: 'STATUS',
    cellRenderer: ({ rowData }: CellParams) => {
      return h(StatusCell, {
        status: rowData.statusDescription as AppointmentStatus,
      })
    },
  },
  {
    minWidth: 86,
    maxWidth: 160,
    flexGrow: 1,
    title: 'PICKUP #',
    cellRenderer: PickupNumberCell,
  },
  {
    minWidth: 140,
    maxWidth: 300,
    flexGrow: 2,
    title: 'LICENSE PLATE',
    cellRenderer: ({ rowData }: CellParams) => {
      if (!rowData.transaction) {
        return h('div')
      }
      return h(LicensePlateCell, {
        transaction: rowData.transaction,
      })
    },
  },
  {
    minWidth: 200,
    title: '',
    cellRenderer: ({ rowData }: CellParams) => {
      const reschedulingInfo = getReschedulingInfoForRow(rowData)

      return h(ActionButtonsCell, {
        transaction: rowData.transaction,
        rescheduling: !!reschedulingInfo,
        reschedulingStatus: reschedulingInfo?.state.status,
        rowData,
        openRescheduleRowKey: openRescheduleRowKey.value,
        onToggleReschedulePopover: (rk: string) => toggleReschedulePopover(rk),
        onCloseReschedulePopover: closeReschedulePopover,
        onRescheduleBegun: rescheduling.registerReschedule,
      })
    },
  },
  {
    minWidth: 120,
    title: '',
    cellRenderer: ({ rowData }: CellParams) => {
      const allTags = rowData.transaction?.allTags || []

      return h(Fragment, null, [
        h(TransactionActions, { transaction: rowData.transaction }),
        h(ContainerBadges, { badges: allTags, class: 'container-badges' }),
      ])
    },
  },
]

function getReschedulingInfoForRow(
  row: AppointmentTableRow
): RescheduleInfo | undefined {
  if (!row.transaction) return
  if (!row.transaction.primary_appointment) return
  return rescheduling.getRescheduleInfo(
    row.transaction.primary_appointment.appointment.id
  )
}

const minColsWidth = computed(() => {
  return props.minWidth
})

function getShiftGroupID(row: AppointmentTableRow): string {
  return row.shiftGroup.key
}

function getSubGroupID(row: AppointmentTableRow): string {
  return String(row.transaction?.primary_appointment.appointment.id || '')
}

// Convert height to string for style binding
const tableHeight = computed(() => `${props.height}px`)
// TODO: Re-work these things for new table

function onHeaderChange(headerid: string | null) {
  if (!headerid) return
  // The number of shifts will always be relatively small (like less than 20, normally
  // like 6) so it's fine this is O(n) I feel
  const shiftGroup = props.appointmentShiftGroups.find(
    (shiftGroup) => shiftGroup.key === headerid
  )
  if (shiftGroup) {
    emit('firstVisibleShiftChanged', shiftGroup)
  }
}

const extraRowProps = ({ rowData }: { rowData: AppointmentTableRow }) => {
  return {
    onMouseenter: () => {
      hoveredRowKey.value = rowData.rowKey
    },
    onMouseleave: () => {
      hoveredRowKey.value = null
    },
  }
}

const rowClass = ({ rowData }: { rowData: AppointmentTableRow }) => {
  const classes = []
  if (rowData.shiftGroup.key === props.selectedShift?.key) {
    classes.push('is-selected')
  }
  const reschedulingInfo = getReschedulingInfoForRow(rowData)
  if (reschedulingInfo?.state) {
    if (
      reschedulingInfo.state.status === 'pending' ||
      reschedulingInfo.state.status === 'booked'
    ) {
      classes.push('rescheduling')
    } else if (reschedulingInfo.state.status === 'failed') {
      classes.push('rescheduling-failed')
    } else if (reschedulingInfo.state.status === 'rejected') {
      classes.push('rescheduling-rejected')
    }
  }
  return classes
}
</script>

<template>
  <Box
    ref="boxElement"
    class="appointments-table"
    :style="{ minWidth: `${minColsWidth}px`, height: tableHeight }"
  >
    <StickyHeaderVirtualTable
      ref="virtualTableRef"
      :columns="columns"
      :items="rows"
      :row-height="rowHeight"
      :header-height="headerHeight"
      :group-header-height="shiftHeaderHeight"
      :sub-group-header-height="0"
      :get-group-header-id="getShiftGroupID"
      :get-sub-group-header-id="getSubGroupID"
      :row-class="rowClass"
      :row-props="extraRowProps"
      :buffer-pixels="2000"
      @header-change="onHeaderChange"
    >
      <template #group-header="{ item }">
        <ShiftHeaderRow
          :shift-group="item.shiftGroup"
          class="shift-header"
          :class="{
            'is-selected': item.shiftGroup.key === selectedShift?.key,
          }"
        />
      </template>
    </StickyHeaderVirtualTable>
  </Box>
</template>

<style lang="scss" scoped>
.appointments-table {
  @apply text-sm h-full;
  $shift-header-height: v-bind(shiftHeaderHeightPixels);

  :deep(.sticky-header-virtual-table) {
    width: 100%;
  }

  .shift-header {
    height: $shift-header-height !important;
    @apply bg-blue-gray-300 px-2 border-b border-t border-blue-gray-400;

    // This fixes header lower border, otherwise it gets hidden
    position: relative;
    z-index: 20;
    margin-bottom: -1px !important;
    &.is-selected {
      // TODO: Use common vars with ShiftOption.vue
      @apply bg-blue-100 rounded-4px;
      @apply border-blue-700 bg-blue-100 !important;
      border-width: 2px;
    }
  }
  .rescheduling {
    @apply bg-yellow-100;
  }
  .rescheduling-failed {
    @apply bg-red-100;
  }
  .rescheduling-rejected {
    @apply bg-orange-100;
  }
}
</style>
