<script lang="ts" setup>
import { useElementSize } from '@vueuse/core'

import { useUserStore } from '~/stores/user'
import { useGlobalNotificationsStore } from '~/stores/globalNotifications'

import Header from '~/components/navigation/Header.vue'
import LeftNavBarVue from '~/components/navigation/LeftNavBar.vue'
import InvalidCredentialsBanner from '~/components/InvalidCredentialsBanner.vue'
import { contentAreaHeightInjectionKey } from '~/constants'

// Refs
const headerElement = ref<HTMLElement | null>(null)
const notificationsElement = ref<HTMLElement | null>(null)
// Stores
const globalNotificationsStore = useGlobalNotificationsStore()
const userStore = useUserStore()
// Computed
const globalNotificationsToDisplay = computed(() => {
  if (userStore.demo_mode) {
    return []
  } else {
    return globalNotificationsStore.activeNotifications
  }
})
const headerSize = useElementSize(headerElement)
const notificationsSize = useElementSize(notificationsElement)
const windowSize = useWindowSize()

const contentHeight = computed(() => {
  return (
    windowSize.height.value -
    headerSize.height.value -
    notificationsSize.height.value -
    20 // Fudge factor, probably for some padding somewhere
  )
})
provide(contentAreaHeightInjectionKey, contentHeight)
</script>

<template>
  <el-container direction="vertical" class="min-h-full">
    <Header ref="headerElement" />
    <el-container direction="horizontal" class="min-h-full">
      <LeftNavBarVue />
      <main class="p-1 m-2px w-full flex-1" style="width: 0px">
        <div
          ref="notificationsElement"
          class="sticky left-0 right-0 z-10 bg-white"
        >
          <GlobalNotification
            v-for="notification in globalNotificationsToDisplay"
            :key="notification.id"
            :notification="notification"
          />
          <InvalidCredentialsBanner
            v-if="!userStore.isSubCarrier && !userStore.demo_mode"
          />
        </div>
        <el-scrollbar :style="{ height: `${contentHeight}px` }">
          <div :style="{ height: `${contentHeight}px` }">
            <router-view />
          </div>
        </el-scrollbar>
      </main>
      <!-- <el-footer class="text-center p-2">
          © 2022 - Nathaniel Brown
        </el-footer> -->
    </el-container>
  </el-container>
</template>
