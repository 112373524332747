<script lang="ts" setup>
import { DateTime } from 'luxon'
import type { PropType } from 'vue'
import { ElMessage, ElMessageBox, ElNotification } from 'element-plus'

import type { Container } from '~/models/containers'
import {
  AutoBookApi,
  AutoBookRequestStatus,
  DayPreferenceType,
  HoldType,
  OrganizationsApi,
  PreferenceOption,
} from '~/services/apiClient'
import type {
  AutoBookRequestPreset,
  AutoBookingSettings,
  DayOfWeekPreference,
  GETAutoBookRequest,
  POSTAutoBookRequest,
  TimePreference,
} from '~/services/apiClient'
import BookingForm from '~/components/inputs/BookingForm.vue'
import { useUserStore } from '~/stores/user'

const props = defineProps({
  containers: { type: Array as PropType<Container[]>, required: true },
})
const emit = defineEmits<{ (e: 'close'): void }>()
const hasBookedContainers = computed(() => {
  return props.containers.some((c) => c.is_in_booked_state)
})

const userStore = useUserStore()
const smartRescheduleApplies = computed(() => {
  return hasBookedContainers.value
})
const updatingForm = ref(false)
const autoBookPreset = ref<AutoBookRequestPreset | undefined>(undefined)
const autoBookPresetOptions = computed(() => {
  if (!userStore.currentOrg?.config) {
    return []
  } else if (!userStore.currentOrg?.config.autoBookPresets) {
    return []
  }
  return userStore.currentOrg?.config.autoBookPresets.map((preset) => ({
    label: preset.name,
    value: preset,
  }))
})
const categorizedLFDs = computed(() => {
  return categorizeLFDs()
})
const lfdWarningText = computed(() => {
  return createLFDWarningText()
})

const numLFDsByDate = computed(() => {
  return createNumLFDsByDate()
})
const validContainers = computed(() => {
  // All containers are considered valid now
  return props.containers
})
const containersWithAutoBookOn = computed(() => {
  return validContainers.value.filter((c) => c.auto_book_on)
})
const dialogOpen = ref(true)
const dayOptions = ref([
  { label: 'First day with appointments', value: DayPreferenceType.Earliest },
  {
    label: 'Before LFD if possible with preferred time',
    value: DayPreferenceType.Anytime,
  },
  {
    label: 'Custom by date (ex. 8/12)',
    value: DayPreferenceType.Custom,
  },
  {
    label: 'Custom by day of week (ex. Tuesday)',
    value: DayPreferenceType.CustomByDayOfWeek,
  },
  {
    label: 'Relative to LFD (days to LFD)',
    value: DayPreferenceType.RelativeToLfd,
  },
])
// map the HoldType enum to an array of objects with label and value properties
const holdOptions = ref(
  Object.keys(HoldType).map((key) => ({
    label: HoldType[key as keyof typeof HoldType],
    value: HoldType[key as keyof typeof HoldType],
  }))
)
const bucketHours = ref(3)
const hotContainer = ref(false)
const bucketHoursOptions = ref([0.5, 1, 2, 3, 4, 6])
function bucketHoursOptionLabel(hours: number) {
  if (hours < 1) {
    return `${hours * 60} minutes`
  }
  return `${hours} hour${hours > 1 ? 's' : ''}`
}
function makeTimeFromHours(hours: number) {
  // if its a fraction of an hour, return the minutes
  const minuteFraction = hours % 1
  if (minuteFraction > 0) {
    return `${Math.floor(hours)}:${minuteFraction * 60}`
  }
  return hours.toString().padStart(2, '0') + ':00'
}
function generateTimePreferences(): TimePreference[] {
  const preferences: TimePreference[] = []
  let hour = 0
  while (hour < 24) {
    let preference: PreferenceOption
    if (bucketHours.value < 3) {
      preference = PreferenceOption.NotAllowed
    } else if (hour < 6) {
      preference = PreferenceOption.NotAllowed
    } else if (hour > 18) {
      preference = PreferenceOption.NotAllowed
    } else {
      preference = PreferenceOption.Medium
    }
    preferences.push({
      start_local: makeTimeFromHours(hour),
      preference,
    })
    hour += bucketHours.value
  }
  return preferences
}
const form = reactive({
  day_preference: DayPreferenceType.Earliest as DayPreferenceType,
  time_preferences: generateTimePreferences(),
  min_advance_notice_hours: 12,
  allow_friday_second_shift: true,
  allow_saturdays: true,
  allow_sundays: false,
  after_custom_days_preference: PreferenceOption.Low as PreferenceOption,
  holds_to_block_booking: [] as HoldType[],
  hot_container: false,
})
watch([bucketHours], () => {
  form.time_preferences = generateTimePreferences()
})
const lfd = ref(null as DateTime | null)

const customBookingDays = ref<
  { day: string; label: string; preference: PreferenceOption }[]
>([])

// +/- 5 days
const daysToLFD = ref<
  { days_to_lfd: number; label: string; preference: PreferenceOption }[]
>([])

function customBookingDayLabel(day: DateTime) {
  return day.toFormat('MM/dd (ccc)')
}
function createRelativeToLFDLabel(daysToLFD: number) {
  if (daysToLFD === 0) {
    return lfd.value?.toFormat('MM/dd (ccc)') || '???'
  } else if (daysToLFD < 0) {
    return `${Math.abs(daysToLFD)} days after LFD`
  } else {
    return `${daysToLFD} days before LFD`
  }
}
function categorizeLFDs() {
  const lfdCategories = new Map<string, string[]>()
  for (const container of validContainers.value) {
    if (container.last_free_day) {
      const daysAfterLFD = DateTime.now().diff(
        container.last_free_day,
        'days'
      ).days
      if (daysAfterLFD === 0) {
        lfdCategories.set('onLFD', [
          ...(lfdCategories.get('onLFD') || []),
          container.number,
        ])
      } else if (daysAfterLFD < 0) {
        lfdCategories.set('beforeLFD', [
          ...(lfdCategories.get('beforeLFD') || []),
          container.number,
        ])
      } else {
        lfdCategories.set('afterLFD', [
          ...(lfdCategories.get('afterLFD') || []),
          container.number,
        ])
      }
    } else {
      lfdCategories.set('noLFD', [
        ...(lfdCategories.get('noLFD') || []),
        container.number,
      ])
    }
  }
  return lfdCategories
}

function createLFDWarningText() {
  const numAfterLFD = categorizedLFDs.value.get('afterLFD')?.length || 0
  const numNoLFD = categorizedLFDs.value.get('noLFD')?.length || 0
  // make a warning string for past lfd and no LFD
  let desc = ''
  if (numAfterLFD > 0) {
    desc += `${numAfterLFD} container${
      numAfterLFD > 1 ? 's are' : ' is'
    } past LFD. `
  }
  if (numNoLFD > 0) {
    desc += `${numNoLFD} container${
      numNoLFD > 1 ? 's do' : ' does'
    } not have an LFD. `
  }
  return desc
}

function createNumLFDsByDate() {
  // create a description of the number of LFDs by date
  const numLFDsByDate = new Map<string, number>()
  for (const container of validContainers.value) {
    if (container.last_free_day) {
      const date = container.last_free_day.toISODate()
      if (numLFDsByDate.has(date)) {
        numLFDsByDate.set(date, numLFDsByDate.get(date)! + 1)
      } else {
        numLFDsByDate.set(date, 1)
      }
    }
  }
  // mapping of date to number of LFDs as a string
  const numLFDsByDateDesc = new Map<string, string>()
  for (const [date, numLFDs] of numLFDsByDate) {
    numLFDsByDateDesc.set(date, `${numLFDs} LFD(s)`)
  }
  return numLFDsByDateDesc
}

function addDayBeforeLfd() {
  const firstDayRelativeToLfd = daysToLFD.value[0].days_to_lfd
  // insert as first value
  const desc = createRelativeToLFDLabel(firstDayRelativeToLfd + 1)
  daysToLFD.value.unshift({
    days_to_lfd: firstDayRelativeToLfd + 1,
    label: desc,
    preference: PreferenceOption.Medium,
  })
  // sort descending
}
function addDayAfterLfd() {
  const lastDayRelativeToLfd =
    daysToLFD.value[daysToLFD.value.length - 1].days_to_lfd

  const newDaysRelativeToLfd = lastDayRelativeToLfd - 1
  const desc = createRelativeToLFDLabel(newDaysRelativeToLfd)
  daysToLFD.value.push({
    days_to_lfd: newDaysRelativeToLfd,
    label: desc,
    preference: PreferenceOption.Medium,
  })
}
function removeDayBeforeLfd() {
  if (daysToLFD.value.length > 1) {
    daysToLFD.value.shift()
  }
}
function removeDayAfterLfd() {
  if (daysToLFD.value.length > 1) {
    daysToLFD.value.pop()
  }
}

function addCustomBookingDay() {
  const lastExistingDay = DateTime.fromISO(
    customBookingDays.value[customBookingDays.value.length - 1].day
  )
  let day = lastExistingDay.plus({ days: 1 })
  if (day < DateTime.now().startOf('day')) {
    day = DateTime.now().startOf('day')
  }
  customBookingDays.value.push({
    day: day.toISODate(),
    label: customBookingDayLabel(day),
    preference: PreferenceOption.Medium,
  })
}
function removeCustomBookingDay() {
  if (customBookingDays.value.length > 1) {
    customBookingDays.value.pop()
  }
}
onMounted(() => {
  if (
    validContainers.value.length === 1 &&
    validContainers.value[0].import_status?.last_free_day
  ) {
    lfd.value = validContainers.value[0].import_status.last_free_day
  }
  const now = DateTime.local()
  for (let i = 0; i < 7; i++) {
    const day = now.startOf('day').plus({ days: i })
    customBookingDays.value.push({
      label: customBookingDayLabel(day),
      day: day.toISODate(),
      preference:
        lfd.value && day > lfd.value
          ? PreferenceOption.Low
          : PreferenceOption.Medium,
    })
  }
  // num days daysToLFD
  for (let i = -5; i <= 5; i++) {
    const days_to_lfd = i
    if (i < 0) {
      // after LFD
      const desc = createRelativeToLFDLabel(days_to_lfd)
      daysToLFD.value.push({
        days_to_lfd,
        label: desc,
        preference: PreferenceOption.NotAllowed,
      })
    } else if (i === 0) {
      daysToLFD.value.push({
        days_to_lfd,
        label: createRelativeToLFDLabel(0),
        preference: PreferenceOption.Medium,
      })
    } else {
      // before LFD
      const desc = createRelativeToLFDLabel(days_to_lfd)
      daysToLFD.value.push({
        days_to_lfd,
        label: desc,
        preference: PreferenceOption.Medium,
      })
    }
    // sort descending
    daysToLFD.value.sort((a, b) => b.days_to_lfd - a.days_to_lfd)
  }
  if (
    validContainers.value.length === 1 &&
    validContainers.value[0].auto_book_request_status
  ) {
    loadExistingSettings(validContainers.value[0].number)
  }
})

const customDayPreferencesByDayOfWeek = ref([
  { day_of_week: 'Monday', preference: PreferenceOption.Medium },
  { day_of_week: 'Tuesday', preference: PreferenceOption.Medium },
  { day_of_week: 'Wednesday', preference: PreferenceOption.Medium },
  { day_of_week: 'Thursday', preference: PreferenceOption.Medium },
  { day_of_week: 'Friday', preference: PreferenceOption.Medium },
  { day_of_week: 'Saturday', preference: PreferenceOption.NotAllowed },
  { day_of_week: 'Sunday', preference: PreferenceOption.NotAllowed },
] as DayOfWeekPreference[])

const loading = ref(false)
const existingSettings = ref<GETAutoBookRequest | undefined>(undefined)
watch([hotContainer], () => {
  if (!loading.value) {
    if (hotContainer.value && form.hot_container !== hotContainer.value) {
      form.day_preference = DayPreferenceType.Earliest
      form.min_advance_notice_hours = 3
      form.holds_to_block_booking = []
      for (const time_preference of form.time_preferences) {
        time_preference.preference = PreferenceOption.High
      }
    }
    form.hot_container = hotContainer.value
  }
})

function updateAutoBookFromRaw(autoBook: GETAutoBookRequest) {
  updateFormFromRaw(autoBook.settings)
  existingSettings.value = autoBook
}
function updateFormFromRaw(raw: AutoBookingSettings) {
  // clear holds
  form.holds_to_block_booking = []
  form.hot_container = raw.ignore_capacity_settings || false
  hotContainer.value = form.hot_container
  form.day_preference = raw.day_preference
  form.min_advance_notice_hours = raw.min_advance_notice_hours
  const firstBucketHour = parseInt(
    raw.time_preferences[0].start_local.split(':')[0]
  )

  const secondBucketHour = parseInt(
    raw.time_preferences[1].start_local.split(':')[0]
  )
  if (firstBucketHour === secondBucketHour) {
    // parse the minutes
    const firstBucketMinute = parseInt(
      raw.time_preferences[0].start_local.split(':')[1]
    )
    const secondBucketMinute = parseInt(
      raw.time_preferences[1].start_local.split(':')[1]
    )
    // absolute value of the difference in minutes
    bucketHours.value = Math.abs(secondBucketMinute - firstBucketMinute) / 60
  } else {
    bucketHours.value = secondBucketHour - firstBucketHour
  }
  nextTick(() => {
    form.time_preferences = raw.time_preferences
  })
  form.allow_saturdays =
    raw.allow_saturdays === undefined ? true : raw.allow_saturdays
  form.allow_sundays =
    raw.allow_sundays === undefined ? false : raw.allow_sundays
  form.allow_friday_second_shift =
    raw.allow_friday_second_shift === undefined
      ? true
      : raw.allow_friday_second_shift
  if (raw.custom_day_preferences) {
    const newCustomBookingDays = []
    for (const [index, preference] of raw.custom_day_preferences.entries()) {
      const day = DateTime.fromISO(preference.day)
      if (
        day >= DateTime.now().startOf('day') ||
        index === raw.custom_day_preferences.length - 1
      ) {
        newCustomBookingDays.push({
          day: preference.day,
          label: customBookingDayLabel(day),
          preference: preference.preference,
        })
      }
    }
    customBookingDays.value = newCustomBookingDays
    form.after_custom_days_preference =
      raw.after_custom_days_preference || PreferenceOption.Low
    for (const dayPreference of raw.custom_day_preferences) {
      for (const defaultDayPreference of customBookingDays.value) {
        if (defaultDayPreference.day === dayPreference.day) {
          defaultDayPreference.preference = dayPreference.preference
        }
      }
    }
  }
  if (
    raw.relative_to_lfd_preferences &&
    raw.relative_to_lfd_preferences.length > 0
  ) {
    // sort descending
    const newDaysRelativeToLfd = []
    raw.relative_to_lfd_preferences.sort(
      (a, b) => b.days_to_lfd - a.days_to_lfd
    )
    for (const preference of raw.relative_to_lfd_preferences) {
      newDaysRelativeToLfd.push({
        days_to_lfd: preference.days_to_lfd,
        label: createRelativeToLFDLabel(preference.days_to_lfd),
        preference: preference.preference,
      })
    }
    daysToLFD.value = newDaysRelativeToLfd
  }
  if (raw.holds_to_block_booking) {
    form.holds_to_block_booking = Array.from(raw.holds_to_block_booking)
  }
  if (raw.custom_day_of_week_preferences) {
    for (const [
      index,
      dayPreference,
    ] of raw.custom_day_of_week_preferences.entries()) {
      customDayPreferencesByDayOfWeek.value[index].preference =
        dayPreference.preference
    }
  }
}

function loadExistingSettings(containerNumber: string) {
  loading.value = true
  const api = new AutoBookApi()
  api
    .loadAutoBookSettingForContainerAppointmentsBookingAutoContainerNumberGet(
      containerNumber
    )
    .then((resp) => {
      updateAutoBookFromRaw(resp.data)
    })
    .finally(() => {
      loading.value = false
    })
}

const formRefs = ref<InstanceType<typeof BookingForm>[]>([])
defineExpose({ formRefs }) // I don't know why this is necessary but it is

async function updateAutoBooking() {
  const settings: POSTAutoBookRequest[] = []
  const containerLookup = new Map(
    validContainers.value.map((c) => [c.number, c])
  )
  const validations = []
  for (const form of formRefs.value!) {
    validations.push(form.validate())
  }
  let results
  try {
    results = await Promise.all(validations)
  } catch (e) {
    ElMessage.error('Please fill out all required fields')
    return
  }
  // I think maybe this never gets called, but leaving just in case
  if (results.includes(false)) {
    ElMessage.error('Please fix the errors in the form')
    return
  }
  for (const formRef of formRefs.value!) {
    const containerNumber = formRef.containerNumber
    const container = containerLookup.get(containerNumber)
    if (!container) {
      throw new Error(`Container ${containerNumber} not found`)
    }
    const { masterBL, licensePlate, ownChassis } = formRef.getValues()
    const bookingSettings = makeSettingsFromForm(
      masterBL,
      licensePlate,
      ownChassis
    )
    // if the container is in booked state and the user can smart reschedule,
    // add the setting reschedule_existing_appointment = true
    if (container.is_in_booked_state) {
      bookingSettings.reschedule_existing_appointment = true
    }

    settings.push({
      container_number: container.number,
      enabled: true,
      settings: bookingSettings,
      id: existingSettings.value?.id,
    })
  }
  if (settings.length === 0) {
    ElMessage.error('No containers to auto-book')
    return
  }
  const api = new AutoBookApi()
  api.saveAutoBookSettingsAppointmentsBookingAutoPost(settings).then(() => {
    validContainers.value.forEach((container) => {
      // TODO: Get rid of use of enabled and just set status
      container.auto_book_on = true
      container.auto_book_request_status = AutoBookRequestStatus.Requested
      container.incrementFrontendVersion()
    })
    ElNotification.success('Auto-booking settings updated')
    emit('close')
  })
}

function disableAutoBooks() {
  const api = new AutoBookApi()
  api
    .cancelAutoBooksAppointmentsBookingAutoCancelPost({
      container_numbers: props.containers.map((c) => c.number),
    })
    .then(() => {
      props.containers.forEach((container) => {
        container.auto_book_on = false
        container.auto_book_request_status = AutoBookRequestStatus.Cancelled
        container.incrementFrontendVersion()
      })
      ElNotification.success('Auto-booking turned off')
      emit('close')
    })
}
watch(autoBookPreset, () => {
  if (!autoBookPreset.value || autoBookPreset.value === undefined) {
    return
  }
  updatingForm.value = true
  const autoBookPresetCopy = JSON.parse(
    JSON.stringify(autoBookPreset.value)
  ) as AutoBookRequestPreset
  updateFormFromRaw(autoBookPresetCopy.settings)
  updatingForm.value = false
})

watch(form, () => {
  nextTick(() => {
    if (!autoBookPreset.value || autoBookPreset.value === undefined) {
      return
    }
    if (updatingForm.value === true) {
      return
    }
    const autoBookPresetCopy = JSON.parse(
      JSON.stringify(autoBookPreset.value)
    ) as AutoBookRequestPreset
    let holdsPreset: HoldType[] = []
    if (autoBookPresetCopy.settings.holds_to_block_booking) {
      holdsPreset = Array.from(
        autoBookPresetCopy.settings.holds_to_block_booking
      )
    }
    for (const hold in form.holds_to_block_booking) {
      const holdType: HoldType = hold as HoldType
      if (!holdsPreset.includes(holdType)) {
        autoBookPreset.value = undefined
        return
      }
    }
    if (form.day_preference !== autoBookPresetCopy.settings.day_preference) {
      autoBookPreset.value = undefined
      return
    }
    if (
      form.min_advance_notice_hours !==
      autoBookPresetCopy.settings.min_advance_notice_hours
    ) {
      autoBookPreset.value = undefined
      return
    }
    if (
      form.allow_friday_second_shift !==
      autoBookPresetCopy.settings.allow_friday_second_shift
    ) {
      autoBookPreset.value = undefined
      return
    }

    if (form.allow_saturdays !== autoBookPresetCopy.settings.allow_saturdays) {
      autoBookPreset.value = undefined
      return
    }
    if (form.allow_sundays !== autoBookPresetCopy.settings.allow_sundays) {
      autoBookPreset.value = undefined
      return
    }
    if (
      form.hot_container !==
      autoBookPresetCopy.settings.ignore_capacity_settings
    ) {
      autoBookPreset.value = undefined
      return
    }
    const timePreferencesCopy = JSON.parse(
      JSON.stringify(form.time_preferences)
    ) as TimePreference[]
    for (const [index, timePreference] of timePreferencesCopy.entries()) {
      if (
        timePreference.preference !==
        autoBookPresetCopy.settings.time_preferences[index].preference
      ) {
        autoBookPreset.value = undefined
        return
      }
    }
  })
})
function makeSettingsFromForm(
  masterBl: string | undefined,
  licensePlate: string | undefined,
  ownChass: boolean | undefined
) {
  let requestHoldsToBlockBooking: HoldType[] | undefined
  if (form.holds_to_block_booking.length > 0) {
    requestHoldsToBlockBooking = form.holds_to_block_booking
  } else {
    requestHoldsToBlockBooking = undefined
  }
  if (!ownChass) {
    ownChass = formRefs.value[0].getValues().ownChassis
  }
  let allow_saturdays = form.allow_saturdays
  let allow_sundays = form.allow_sundays
  if (
    form.day_preference === DayPreferenceType.Custom ||
    form.day_preference === DayPreferenceType.CustomByDayOfWeek
  ) {
    allow_saturdays = true
    allow_sundays = true
  }
  const settings: AutoBookingSettings = {
    day_preference: form.day_preference,
    time_preferences: form.time_preferences,
    allow_saturdays,
    allow_sundays,
    allow_friday_second_shift: form.allow_friday_second_shift,
    custom_day_preferences:
      form.day_preference === DayPreferenceType.Custom
        ? customBookingDays.value
        : undefined,
    after_custom_days_preference: form.after_custom_days_preference,
    relative_to_lfd_preferences:
      form.day_preference === DayPreferenceType.RelativeToLfd
        ? daysToLFD.value
        : undefined,
    custom_day_of_week_preferences:
      form.day_preference === DayPreferenceType.CustomByDayOfWeek
        ? customDayPreferencesByDayOfWeek.value
        : undefined,
    timezone: DateTime.now().toFormat('z'),
    min_advance_notice_hours: form.min_advance_notice_hours,
    holds_to_block_booking: requestHoldsToBlockBooking,
    ignore_capacity_settings: form.hot_container,
    own_chassis: ownChass,
    license_plate: licensePlate,
    master_bill_of_lading: masterBl,
  }
  return settings
}
function formatTime(
  hours: number,
  minutes: number,
  includeMeridian: boolean
): string {
  let meridian = hours >= 12 ? 'PM' : 'AM'
  if (hours === 24) {
    meridian = 'AM'
  }
  let hour = hours % 12
  if (hour === 0) {
    hour = 12
  }

  if (minutes) {
    return `${hour}:${minutes}` + (includeMeridian ? `${meridian}` : '')
  }
  return `${hour}` + (includeMeridian ? `${meridian}` : '')
}
function timeSlotDescription(startTime: string): string {
  const hour = parseInt(startTime.split(':')[0])
  const minute = parseInt(startTime.split(':')[1])
  if (bucketHours.value < 1) {
    // we really only need the end time to have the meridian
    // if both start and end have it, it gets too wide
    if (minute === 0) {
      return (
        formatTime(hour, minute, false) +
        ' - ' +
        formatTime(hour, bucketHours.value * 60, true)
      )
    } else {
      let nextHour = hour
      if (minute + bucketHours.value * 60 === 60) {
        nextHour = hour + 1
      }
      return (
        formatTime(hour, minute, false) +
        ' - ' +
        formatTime(nextHour, (minute + bucketHours.value * 60) % 60, true)
      )
    }
  }
  return (
    formatTime(hour, minute, true) +
    ' - ' +
    formatTime(hour + bucketHours.value, minute, true)
  )
}
const activeTab = ref('settings')
const allCustomDaysNotAllowed = computed(() => {
  return (
    customBookingDays.value.every(
      (day) => day.preference === PreferenceOption.NotAllowed
    ) && form.after_custom_days_preference === PreferenceOption.NotAllowed
  )
})
const allRelativeDaysNotAllowed = computed(() => {
  return daysToLFD.value.every(
    (day) => day.preference === PreferenceOption.NotAllowed
  )
})

const allDaysOfWeekNotAllowed = computed(() => {
  return customDayPreferencesByDayOfWeek.value.every(
    (day) => day.preference === PreferenceOption.NotAllowed
  )
})
const allTimesNotAllowed = computed(() => {
  return form.time_preferences.every(
    (time) => time.preference === PreferenceOption.NotAllowed
  )
})
function addAutoBookPreset() {
  if (!userStore.currentOrg?.config) {
    return
  }
  ElMessageBox.prompt('Profile name:', 'Save current settings as profile', {
    confirmButtonText: 'Save Profile',
    confirmButtonClass: 'el-button--success',
    cancelButtonText: 'Cancel',
  }).then(({ value }) => {
    const api = new OrganizationsApi()
    if (!userStore.currentOrg?.config) throw new Error('This should not happen')
    const settings = makeSettingsFromForm(undefined, undefined, undefined)
    const newPreset = {
      name: value,
      settings,
    } as AutoBookRequestPreset
    // add the preset to the org config
    const orgConfig = userStore.currentOrg?.config
    orgConfig.autoBookPresets.push(newPreset)
    api
      .updateOrganizationPreferencesOrganizationsPreferencesPut(
        orgConfig.toAPIFormat()
      )
      .then(() => {
        // set the preset to the new preset
        autoBookPreset.value = newPreset
        ElNotification({
          title: 'Profile saved',
          type: 'success',
        })
      })
      .catch(() => {
        ElNotification({
          message: 'Error saving profile',
          type: 'error',
        })
      })
  })
}

function editPresetName(presetToEdit: AutoBookRequestPreset) {
  if (!userStore.currentOrg?.config) {
    return
  }
  ElMessageBox.prompt(
    'Profile name:',
    `Edit the name of the profile "${presetToEdit.name}"`,
    {
      confirmButtonText: 'Save Profile',
      cancelButtonText: 'Cancel',
      inputValue: presetToEdit.name,
    }
  ).then(({ value }) => {
    const api = new OrganizationsApi()
    if (!userStore.currentOrg?.config) throw new Error('This should not happen')
    // remove the preset from the org config
    const orgConfig = userStore.currentOrg?.config
    orgConfig.autoBookPresets = orgConfig.autoBookPresets.filter(
      (preset) => preset.name !== presetToEdit.name
    )
    // add the preset to the org config
    const settings = makeSettingsFromForm(undefined, undefined, undefined)
    const newPreset = {
      name: value,
      settings,
    } as AutoBookRequestPreset
    orgConfig.autoBookPresets.push(newPreset)
    api
      .updateOrganizationPreferencesOrganizationsPreferencesPut(
        orgConfig.toAPIFormat()
      )
      .then(() => {
        // set the preset to the new preset
        autoBookPreset.value = newPreset
        ElNotification({
          title: 'Profile saved',
          type: 'success',
        })
      })
      .catch(() => {
        ElNotification({
          message: 'Error saving profile',
          type: 'error',
        })
      })
  })
}

function deletePreset(presetToDelete: AutoBookRequestPreset) {
  if (!userStore.currentOrg?.config) {
    return
  }
  ElMessageBox.confirm(
    `Are you sure you want to delete the profile "${presetToDelete.name}"?`,
    `Delete profile?`,
    {
      confirmButtonText: 'Yes, delete this profile',
      confirmButtonClass: 'el-button--danger',
      cancelButtonText: 'Cancel',
      type: 'warning',
    }
  ).then(() => {
    const api = new OrganizationsApi()
    if (!userStore.currentOrg?.config) throw new Error('This should not happen')
    // remove the preset from the org config
    const orgConfig = userStore.currentOrg?.config
    orgConfig.autoBookPresets = orgConfig.autoBookPresets.filter(
      (preset) => preset.name !== presetToDelete.name
    )
    api
      .updateOrganizationPreferencesOrganizationsPreferencesPut(
        orgConfig.toAPIFormat()
      )
      .then(() => {
        // remove the preset from the options
        autoBookPresetOptions.value.filter(
          (option) => option.value.name !== presetToDelete.name
        )
        // clear the preset
        autoBookPreset.value = undefined
        ElNotification({
          title: 'Profile deleted',
          type: 'success',
        })
      })
      .catch(() => {
        ElNotification({
          message: 'Error deleting profile',
          type: 'error',
        })
      })
  })
}
const lfdHelpText =
  'If LFD is not found or LFD is more than 14 days out from the current time,' +
  " Auto Book will use discharged time +4 days as the 'LFD'. If we don't have a " +
  'discharged time and the LFD is not found or is more than 14 days out, we will try ' +
  'to book the first available appointment.'
</script>

<template>
  <el-dialog
    v-model="dialogOpen"
    center
    width="1100px"
    :append-to-body="true"
    @close="emit('close')"
  >
    <template #header>
      <div class="text-2xl">Auto Book</div>
    </template>
    <el-tabs v-model="activeTab" class="-mt-8">
      <el-tab-pane label="Settings" name="settings" lazy>
        <template #label>
          <i-mdi-cog class="align-middle" />
          Settings
        </template>
        <div v-loading="loading">
          <div class="flex justify-between mb-5 items-end">
            <h1 class="text-lg break-normal">
              Book appointment{{ validContainers.length !== 1 ? 's' : '' }} for
              {{ validContainers.length }} container{{
                validContainers.length !== 1 ? 's' : ''
              }}:
            </h1>
          </div>
          <div v-for="container in validContainers" :key="container.number">
            <BookingForm
              ref="formRefs"
              :key="container.number"
              :container="container"
              :existing-auto-book-request="existingSettings"
              :inline="true"
              class="ml-6"
            />
          </div>
          <div class="flex justify-between">
            <div class="flex items-center">
              <i-mdi:hot class="align-text-bottom" />
              <strong>Hot Container(s)</strong>
              <el-switch
                v-model="hotContainer"
                active-color="#ff4949"
                inline-prompt
                active-text="HOT"
                class="ml-1"
              />
              <Help title="Hot Containers" class="ml-2px"
                >Only for Hot Containers. This will undermine capacity if used
                excessively. Hot containers are booked first for your org.
                Ignores capacity settings plus some suggested settings.
              </Help>
            </div>
            <!-- </p> -->
            <div class="flex items-center">
              <el-form v-if="autoBookPresetOptions.length > 0">
                <el-select
                  v-model="autoBookPreset"
                  v-tooltip="`Apply a previously saved settings profile`"
                  class="mr-1"
                  :clearable="true"
                  placeholder="Apply Profile"
                  value-key="name"
                  style="min-width: 150px"
                >
                  <el-option
                    v-for="option in autoBookPresetOptions"
                    :key="option.label"
                    :label="option.label"
                    :value="option.value"
                    class="auto-book-preset-option"
                  >
                    <div class="flex justify-between items-center w-full">
                      <span
                        v-tooltip="
                          `Apply settings from the profile &quot;${option.label}&quot;`
                        "
                      >
                        Apply "{{ option.label }}"
                      </span>
                      <div class="ml-2">
                        <el-button
                          v-tooltip="`Edit profile name`"
                          type="warning"
                          size="small"
                          circle
                          plain
                          @click="() => editPresetName(option.value)"
                        >
                          <i-mdi:pencil />
                        </el-button>
                        <el-button
                          v-tooltip="`Delete profile`"
                          type="danger"
                          size="small"
                          circle
                          plain
                          @click="() => deletePreset(option.value)"
                        >
                          <i-mdi:trash-can />
                        </el-button>
                      </div>
                    </div>
                  </el-option>
                </el-select>
              </el-form>
              <el-button
                v-tooltip="
                  `Save your current settings for future use as a &quot;Profile&quot;`
                "
                type="success"
                plain
                class="ml-1"
                @click="addAutoBookPreset"
              >
                <i-mdi:plus class="mr-1 -ml-1" />
                Save Profile
              </el-button>
              <Help title="Auto Book Profiles" class="ml-2px"
                >Save some auto book settings to quickly apply them to
                containers.</Help
              >
            </div>
          </div>
          <p class="mt-1">
            <el-alert v-if="hotContainer" type="warning" :closable="false">
              <i-mdi:information class="align-text-bottom" />
              Only for Hot Containers. This will undermine capacity if used too
              much. Hot containers are booked first for your org. Ignores
              capacity settings plus some suggested settings. Check your
              settings!
            </el-alert>
          </p>
          <p class="text-lg mt-4">
            <strong>Dispatch Buffer Window</strong> minimum hours
            <el-input-number
              v-model="form.min_advance_notice_hours"
              :min="0"
              :max="48"
              size="small"
            />
            needed to dispatch a truck for the appointment
          </p>
          <div class="flex mt-4">
            <!-- Day Preferences -->
            <div class="ml-6 mr-4 break-normal">
              <h3 class="text-lg text-center font-semibold">Day Preference</h3>
              <el-radio-group v-model="form.day_preference" class="mb-2">
                <el-radio
                  v-for="option in dayOptions"
                  :key="option.value"
                  border
                  :label="option.value"
                  class="w-full mb-2"
                  style="margin-right: 0px"
                >
                  {{ option.label }}
                </el-radio>
              </el-radio-group>
              <div
                v-if="form.day_preference === DayPreferenceType.Custom"
                class="mb-5"
              >
                <div v-if="lfdWarningText">
                  <b class="text-red-600"
                    ><i-material-symbols:warning class="align-text-bottom" />{{
                      lfdWarningText
                    }}</b
                  >
                </div>
                <div
                  v-for="bookingDay in customBookingDays"
                  :key="bookingDay.day"
                  class="flex my-1"
                >
                  <div class="custom-day-row">
                    <ColoredBadge
                      v-if="bookingDay.day === lfd?.toISODate()"
                      type="warning"
                    >
                      LFD
                    </ColoredBadge>
                    <ColoredBadge
                      v-else-if="numLFDsByDate.has(bookingDay.day)"
                      class="ml-1"
                      type="warning"
                    >
                      {{ numLFDsByDate.get(bookingDay.day) }}
                    </ColoredBadge>
                    <!-- Spacer -->
                    <div v-else></div>
                    <span class="ml-1">{{ bookingDay.label }}</span>
                  </div>
                  <AutoBookPreference v-model="bookingDay.preference" />
                </div>
                <div class="flex my-1">
                  <div class="custom-day-row">
                    <div></div>
                    After
                    {{ customBookingDays[customBookingDays.length - 1].label }}
                  </div>
                  <AutoBookPreference
                    v-model="form.after_custom_days_preference"
                  />
                </div>
                <el-alert
                  v-if="allCustomDaysNotAllowed"
                  type="error"
                  :closable="false"
                  ><i-material-symbols:warning class="align-text-bottom" />
                  These preferences won't book an appointment! Adjust the custom
                  by date preferences.</el-alert
                >
                <el-alert
                  v-if="
                    form.after_custom_days_preference !==
                    PreferenceOption.NotAllowed
                  "
                  type="warning"
                  :closable="false"
                  ><i-material-symbols:warning class="align-text-bottom" /> All
                  days after
                  {{ customBookingDays[customBookingDays.length - 1].label }}
                  will have the same preference level.</el-alert
                >
                <div class="flex justify-start mt-4 ml-30">
                  <el-button
                    size="small"
                    type="success"
                    plain
                    @click="addCustomBookingDay"
                  >
                    + Add day
                  </el-button>
                  <el-button
                    size="small"
                    type="danger"
                    plain
                    :disabled="customBookingDays.length === 1"
                    @click="removeCustomBookingDay"
                  >
                    - Subtract day
                  </el-button>
                </div>
              </div>
              <div
                v-if="form.day_preference === DayPreferenceType.Anytime"
                class="mb-3"
              >
                <el-alert type="warning" :closable="false">
                  <i-material-symbols:warning class="align-text-bottom" />
                  {{ lfdHelpText }}
                </el-alert>
              </div>
              <div
                v-if="form.day_preference === DayPreferenceType.RelativeToLfd"
                class="mb-5"
              >
                <div v-if="lfdWarningText">
                  <b class="text-red-600"
                    ><i-material-symbols:warning class="align-text-bottom" />{{
                      lfdWarningText
                    }}</b
                  >
                </div>
                <div class="flex justify-start mt-4 ml-30">
                  <el-button
                    size="small"
                    type="success"
                    plain
                    @click="addDayBeforeLfd"
                  >
                    + Add day before
                  </el-button>
                  <el-button
                    size="small"
                    type="danger"
                    plain
                    :disabled="daysToLFD[0].days_to_lfd === 0"
                    @click="removeDayBeforeLfd"
                  >
                    - Subtract day before
                  </el-button>
                  <Help class="ml-1" title="Relative to LFD">{{
                    lfdHelpText
                  }}</Help>
                </div>
                <div
                  v-for="bookingDay in daysToLFD"
                  :key="bookingDay.days_to_lfd"
                  class="flex my-1"
                >
                  <div class="custom-day-row">
                    <ColoredBadge
                      v-if="bookingDay.days_to_lfd === 0"
                      type="warning"
                    >
                      LFD
                    </ColoredBadge>
                    <!-- Spacer -->
                    <div v-else></div>
                    {{ bookingDay.label }}
                  </div>
                  <AutoBookPreference v-model="bookingDay.preference" />
                </div>
                <el-alert
                  v-if="allRelativeDaysNotAllowed"
                  type="error"
                  :closable="false"
                  ><i-material-symbols:warning class="align-text-bottom" />
                  These preferences won't book an appointment! Adjust the custom
                  by date preferences.</el-alert
                >
                <el-alert v-if="lfd === null" type="error" :closable="false">
                  <i-material-symbols:warning class="align-text-bottom" />
                  If no LFD is found, auto book will use discharged date +4
                  days.
                </el-alert>
                <div class="flex justify-start mt-4 ml-30">
                  <el-button
                    size="small"
                    type="success"
                    plain
                    @click="addDayAfterLfd"
                  >
                    + Add day after
                  </el-button>
                  <el-button
                    size="small"
                    type="danger"
                    plain
                    :disabled="
                      daysToLFD[daysToLFD.length - 1].days_to_lfd === 0
                    "
                    @click="removeDayAfterLfd"
                  >
                    - Subtract day after
                  </el-button>
                </div>
              </div>
              <div
                v-if="
                  form.day_preference === DayPreferenceType.CustomByDayOfWeek
                "
                class="mb-5"
              >
                <div
                  v-for="dayOfWeek in customDayPreferencesByDayOfWeek"
                  :key="dayOfWeek.day_of_week"
                  class="flex my-1"
                >
                  <div class="custom-day-row">
                    <!-- Spacer -->
                    <div></div>
                    {{ dayOfWeek.day_of_week }}
                  </div>
                  <AutoBookPreference v-model="dayOfWeek.preference" />
                </div>
                <el-alert
                  v-if="allDaysOfWeekNotAllowed"
                  type="error"
                  :closable="false"
                  ><i-material-symbols:warning class="align-text-bottom" />
                  These preferences won't book an appointment! Adjust the custom
                  by day of week preferences.</el-alert
                >
              </div>
              <div class="mb-3">
                <el-checkbox
                  v-model="form.allow_friday_second_shift"
                  label="Allow Fridays 2nd Shift"
                  border
                />
                <template
                  v-if="
                    form.day_preference !== DayPreferenceType.Custom &&
                    form.day_preference !== DayPreferenceType.CustomByDayOfWeek
                  "
                >
                  <el-checkbox
                    v-model="form.allow_saturdays"
                    label="Allow Saturdays"
                    border
                  />
                  <el-checkbox
                    v-model="form.allow_sundays"
                    label="Allow Sundays"
                    border
                  />
                </template>
              </div>
              <div>
                <h3 class="text-lg text-center font-semibold">
                  Hold Restrictions
                </h3>
                <h4 class="text-xs text-center">
                  <i>Auto Book won't book if any of these holds are present</i>
                </h4>
                <el-select
                  v-model="form.holds_to_block_booking"
                  multiple
                  placeholder="Select"
                  class="w-full mb-2 mt-1"
                >
                  <el-option
                    v-for="item in holdOptions"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value"
                  />
                </el-select>
              </div>
            </div>
            <div class="min-w-350px">
              <!-- Time Preferences -->
              <h3 class="text-lg text-center font-semibold">Time Preference</h3>
              <el-form-item
                label="Bucket size:"
                class="mx-auto text-sm"
                style="max-width: 200px"
              >
                <el-select
                  v-model="bucketHours"
                  size="small"
                  :fit-input-width="true"
                >
                  <el-option
                    v-for="bucketHoursOption in bucketHoursOptions"
                    :key="bucketHoursOption"
                    :label="bucketHoursOptionLabel(bucketHoursOption)"
                    :value="bucketHoursOption"
                  >
                  </el-option>
                </el-select>
              </el-form-item>
              <div
                v-for="time_preference in form.time_preferences"
                :key="time_preference.start_local"
                class="flex h-[26px] items-center"
              >
                <div class="w-[100px] text-xs flex items-center">
                  {{ timeSlotDescription(time_preference.start_local) }}
                </div>
                <div class="-ml-4">
                  <AutoBookPreference v-model="time_preference.preference" />
                </div>
              </div>
              <el-alert v-if="allTimesNotAllowed" type="error" :closable="false"
                ><i-material-symbols:warning class="align-text-bottom" /> These
                preferences won't book an appointment! Adjust time
                preferences.</el-alert
              >
            </div>
          </div>
          <div class="my-1">
            <el-alert type="info" :closable="false">
              <i-mdi:information class="align-text-bottom" />
              When an appointment is found and booked, you will receive a
              confirmation email <i-mdi:email class="align-text-bottom" />
            </el-alert>
          </div>
          <div class="my-1">
            <el-alert type="info" :closable="false">
              <i-mdi:information class="align-text-bottom" />
              You can further control booking times and prevent over booking by
              defining
              <CapacityIcon class="align-text-bottom" />
              <strong class="font-bold underline">
                <router-link :to="{ name: 'capacity' }" target="_blank">
                  Capacity Constraints
                  <i-mdi:open-in-new class="align-text-bottom" />
                </router-link>
              </strong>
            </el-alert>
          </div>
          <div class="my-1">
            <el-alert
              v-if="smartRescheduleApplies"
              type="warning"
              :closable="false"
            >
              <i-icons8:idea class="align-text-bottom" />
              <b> Smart Reschedule:</b> For containers that already have an
              appointment, smart reschedule will check if any available
              appointments are "better" than the existing appointment, according
              to the auto book settings. If so, it will attempt to reschedule.
            </el-alert>
          </div>
        </div>
      </el-tab-pane>
      <el-tab-pane label="History" name="history" lazy>
        <template #label
          ><i-mdi:history class="align-middle" />
          History
        </template>
        <AutoBookHistory :containers="validContainers" />
      </el-tab-pane>
    </el-tabs>

    <template #footer>
      <div v-if="activeTab === 'settings'" class="flex">
        <el-button
          size="large"
          class="flex-1 fs-close-auto-book-dialog"
          @click="dialogOpen = false"
        >
          Cancel
        </el-button>
        <el-button
          v-if="containersWithAutoBookOn.length > 0"
          size="large"
          class="flex-1 fs-turn-off-auto-book"
          type="danger"
          @click="disableAutoBooks"
        >
          Turn Off
        </el-button>
        <el-button
          type="primary"
          size="large"
          class="flex-1 fs-update-auto-book-settings"
          @click="updateAutoBooking"
        >
          <template v-if="hasBookedContainers"> Smart Reschedule </template>
          <template v-else-if="existingSettings && existingSettings.enabled">
            Update
          </template>
          <template v-else> Turn On </template>
        </el-button>
      </div>
      <div v-else class="flex">
        <el-button
          size="large"
          class="flex-1 fs-close-auto-book-dialog"
          @click="dialogOpen = false"
        >
          Close
        </el-button>
        <el-button
          size="large"
          type="primary"
          class="flex-1 fs-close-auto-book-dialog"
          @click="activeTab = 'settings'"
        >
          View Settings
        </el-button>
      </div>
    </template>
  </el-dialog>
</template>

<style lang="scss" scoped>
.custom-day-row {
  @apply min-w-190px text-xs flex items-center text-right justify-between pr-2;
}
</style>

<style lang="scss">
.auto-book-preset-option {
  padding: 0px 12px;
}
</style>
