import { DateTime } from 'luxon'

import type { Appointment } from './appointments'
import type {
  GETBaseAppointmentSlot,
  GETContainerAvailability,
  GETEmptyInAppointmentSlot,
  GETNextAvailable,
  ShippingLine,
  TerminalName,
} from '~/services/apiClient'
import { shiftNameFromTime } from '~/logic/shifts'

export class SimpleAppointmentSlot {
  window_start: DateTime
  has_availability: boolean
  observed: DateTime
  constructor(slot: GETNextAvailable) {
    this.window_start = DateTime.fromISO(slot.window_start)
    this.has_availability = slot.has_availability
    this.observed = DateTime.fromISO(slot.observed)
  }
}

interface BaseAppointmentSlotInputs {
  window_start: string
  window_end?: string
  num_appointments_available?: number
}

export class BaseAppointmentSlot {
  window_start: DateTime
  window_end?: DateTime
  num_appointments_available?: number
  booked_appointment: Appointment | null

  constructor(input: BaseAppointmentSlotInputs) {
    this.window_start = DateTime.fromISO(input.window_start)
    this.window_end = input.window_end
      ? DateTime.fromISO(input.window_end)
      : undefined
    this.num_appointments_available = input.num_appointments_available
    this.booked_appointment = null
  }

  get has_availability(): boolean {
    return (
      // Case where no guidance on slots is provided
      this.num_appointments_available === undefined ||
      this.num_appointments_available === null ||
      // Case where we have appointments counts
      this.num_appointments_available > 0
    )
  }

  get shift_description(): string {
    return shiftNameFromTime(this.window_start)
  }
}

export class AppointmentSlot extends BaseAppointmentSlot {
  terminal: TerminalName
  container_number: string
  from_terminal_block_availability_key?: string
  constructor(
    availability: GETContainerAvailability,
    slot: GETBaseAppointmentSlot
  ) {
    super(slot)
    this.terminal = availability.terminal
    this.container_number = availability.container_number
    this.from_terminal_block_availability_key =
      availability.from_terminal_block_availability_key
  }
}
export function makeDummyAppointmentSlot(
  appointment: Appointment
): AppointmentSlot {
  const dummyBookedAppointmentSlot = new AppointmentSlot(
    {
      terminal: appointment.terminal,
      container_number: appointment.container_number,
      has_availability: false,
      slots: [],
      observed: DateTime.now().toISO(),
    },
    {
      window_start: appointment.window_start.toISO(),
      window_end: appointment.window_end?.toISO(),
      num_appointments_available: 0,
    }
  )
  dummyBookedAppointmentSlot.booked_appointment = appointment
  return dummyBookedAppointmentSlot
}

export class EmptyInAppointmentSlot extends BaseAppointmentSlot {
  group_observed_string: string
  shipping_line: ShippingLine | undefined
  constructor(
    slot: GETEmptyInAppointmentSlot,
    group_observed: string,
    shipping_line: ShippingLine | undefined
  ) {
    super(slot)
    this.group_observed_string = group_observed
    this.shipping_line = shipping_line
  }
}

export class EmptyInAppointmentSlotReading {
  slots: EmptyInAppointmentSlot[]
  observed: DateTime
  constructor(slots: EmptyInAppointmentSlot[], observed: string) {
    this.slots = slots
    this.observed = DateTime.fromISO(observed)
  }
}
