import { DateTime } from 'luxon'
import { defineStore, storeToRefs } from 'pinia'
import { shiftFromTime, sortableShiftKey } from '~/logic/shifts'
import { useAppointmentWithContainerInfoStore } from '~/stores/appointmentsWithContainerInfo'
import type { AppointmentTransaction } from '~/models/groupedAppointments'
import { ShiftGroup } from '~/models/groupedAppointments'
import { Shift } from '~/services/apiClient'
import { groupAppointmentsIntoTransactions } from '~/logic/appointmentTransactionGrouping'
import {
  type AppointmentFilters,
  MoveTypeFilter,
} from '~/components/dispatch_table/types'
import type { AppointmentWithContainerInfo } from '~/models/appointmentWithContainerInfo'

export const useGroupedAppointmentsStore = defineStore(
  'grouped-appointments',
  () => {
    const appointmentsWithContainersStore =
      useAppointmentWithContainerInfoStore()
    const filters = ref<AppointmentFilters>({
      statuses: [],
      terminals: [],
      licensePlate: undefined,
      shippingLine: undefined,
      containerType: undefined,
      moveType: undefined,
      containers: new Set(),
      customerId: undefined,
      selectedTags: [],
      assignedUsers: [],
    })

    // Computed properties
    const appointmentShiftGroups = computed((): ShiftGroup[] => {
      const shifts = groupAppointmentsIntoShifts(
        appointmentsWithContainersStore.appointments,
        filters.value
      )
      shifts.sort((a, b) => {
        return a.sortComparison(b)
      })
      return shifts
    })
    const numAppointments = computed(() => {
      return appointmentsWithContainersStore.appointments.length
    })
    const { appointments, loading } = storeToRefs(
      appointmentsWithContainersStore
    )
    return {
      appointmentShiftGroups,
      loading,
      appointments,
      load: appointmentsWithContainersStore.load,
      numAppointments,
      filters,
    }
  }
)

export function groupAppointmentsIntoShifts(
  appointments: AppointmentWithContainerInfo[],
  filters: AppointmentFilters
): ShiftGroup[] {
  const map = new Map<string, ShiftGroup>()
  // Keep the previous shift as "current" for two hours after it has really ended
  const currentShiftDate = shiftFromTime(DateTime.now().minus({ hours: 2 }))[0]
  let latestShiftDate = currentShiftDate.plus({ days: 4 })
  // Group into transactions
  const transactions = groupAppointmentsIntoTransactions(appointments)
  // Filter transactions based on filters
  const filteredTransactions = transactions.filter((trx) =>
    matchesFilters(trx, filters)
  )
  // Categorize by shift
  filteredTransactions.forEach((trx) => {
    const shiftKey = trx.sortable_shift_key
    let shiftGroup = map.get(shiftKey)
    if (!shiftGroup) {
      shiftGroup = new ShiftGroup(trx.shiftDate, trx.shift)
      map.set(shiftKey, shiftGroup)
    }
    shiftGroup.addAppointment(trx)
    if (shiftGroup.date > latestShiftDate) {
      latestShiftDate = shiftGroup.date
    }
  })
  // Fill in shifts with no appointments
  let dateCursor = currentShiftDate
  while (dateCursor <= latestShiftDate) {
    // We only fill in first and second shifts so that 3rd shift is only shown if
    // it has appointments
    const firstShiftKey = sortableShiftKey(dateCursor, Shift.First)
    // const secondShiftKey = sortableShiftKey(dateCursor, Shift.Second)
    if (!map.has(firstShiftKey)) {
      map.set(firstShiftKey, new ShiftGroup(dateCursor, Shift.First))
    }
    // if (!map.has(secondShiftKey)) {
    //   map.set(secondShiftKey, new ShiftGroup(dateCursor, Shift.Second))
    // }
    dateCursor = dateCursor.plus({ days: 1 })
  }
  // Sort by window start within each shift
  map.forEach((shiftGroup) => {
    shiftGroup.sortAppointments()
  })
  const shifts = Array.from(map.values())
  shifts.sort((a, b) => {
    return a.sortComparison(b)
  })
  return shifts
}

export function matchesFilters(
  trx: AppointmentTransaction,
  filters: AppointmentFilters
) {
  if (filters.containers.size) {
    if (
      !filters.containers.has(
        trx.primary_appointment.appointment.container_number
      ) &&
      // if there is a secondary appointment and it has a container number then check that as well
      (!trx.secondary_appointment ||
        !filters.containers.has(
          trx.secondary_appointment.appointment.container_number
        ))
    )
      return false
  }
  if (filters.terminals.length) {
    if (!filters.terminals.includes(trx.terminal)) return false
  }
  if (!filters.statuses || filters.statuses.length === 0) {
    return false
  }
  const trxStatus =
    trx.inbound_appointment?.appointment.status ||
    trx.outbound_appointment?.appointment.status
  if (!trxStatus || !filters.statuses.includes(trxStatus)) {
    return false
  }
  if (filters.licensePlate) {
    if (
      trx.primary_appointment.appointment.truck_license_plate_number !==
      filters.licensePlate
    )
      return false
  }
  if (filters.shippingLine) {
    if (
      trx.inbound_appointment?.watch?.shipping_line !== filters.shippingLine &&
      trx.outbound_appointment?.watch?.shipping_line !== filters.shippingLine
    )
      return false
  }
  if (filters.containerType) {
    if (
      trx.inbound_appointment?.watch?.container_type !==
        filters.containerType &&
      trx.outbound_appointment?.watch?.container_type !== filters.containerType
    )
      return false
  }
  if (filters.moveType) {
    switch (filters.moveType) {
      case MoveTypeFilter.HasLoadOut:
        if (
          !trx.outbound_appointment ||
          !trx.outbound_appointment.appointment.loaded
        ) {
          return false
        }
        break
      case MoveTypeFilter.HasEmptyIn:
        if (
          !trx.inbound_appointment ||
          trx.inbound_appointment.appointment.loaded
        ) {
          return false
        }
        break
      case MoveTypeFilter.EmptyInOnly:
        if (
          trx.outbound_appointment ||
          trx.inbound_appointment?.appointment.loaded
        ) {
          return false
        }
        break
      case MoveTypeFilter.LoadOutOnly:
        if (
          trx.inbound_appointment ||
          !trx.outbound_appointment?.appointment.loaded
        ) {
          return false
        }
        break
      case MoveTypeFilter.HasLoadIn:
        if (
          !trx.inbound_appointment ||
          !trx.inbound_appointment.appointment.loaded
        ) {
          return false
        }
        break
      case MoveTypeFilter.HasEmptyOut:
        if (
          !trx.outbound_appointment ||
          trx.outbound_appointment.appointment.loaded
        ) {
          return false
        }
        break
      case MoveTypeFilter.LoadInOnly:
        if (
          trx.outbound_appointment ||
          !trx.inbound_appointment?.appointment.loaded
        ) {
          return false
        }
        break
      case MoveTypeFilter.EmptyOutOnly:
        if (
          trx.inbound_appointment ||
          trx.outbound_appointment?.appointment.loaded
        ) {
          return false
        }
        break
      case MoveTypeFilter.Dual:
        if (!trx.outbound_appointment || !trx.inbound_appointment) {
          return false
        }
        break
      default:
        throw new Error(`Unknown move type filter: ${filters.moveType}`)
    }
  }
  // Customer ID
  if (filters.customerId) {
    if (
      trx.inbound_appointment?.watch?.customer_id !== filters.customerId &&
      trx.outbound_appointment?.watch?.customer_id !== filters.customerId
    ) {
      return false
    }
  }
  // Tags
  // We do "has all tags" filtering
  for (const tag of filters.selectedTags) {
    if (trx.inbound_appointment?.watch?.tagsSet.has(tag)) {
      continue
    }
    if (trx.outbound_appointment?.watch?.tagsSet.has(tag)) {
      continue
    }
    return false
  }
  if (
    Array.isArray(filters.assignedUsers) &&
    filters.assignedUsers.length > 0
  ) {
    const userIds = new Set([
      ...(trx.inbound_appointment?.watch?.assignedUsers || []).map((u) => u.id),
      ...(trx.outbound_appointment?.watch?.assignedUsers || []).map(
        (u) => u.id
      ),
    ])
    const hasMatchingUser = filters.assignedUsers.some((user_id) =>
      userIds.has(user_id)
    )
    if (!hasMatchingUser) {
      return false
    }
  }

  return true
}
