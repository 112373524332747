<script lang="ts" setup>
import type { DateTime } from 'luxon'
import ContainersGroupedByDate from '~/components/display/containers/ContainersGroupedByDate.vue'
import type { Container } from '~/models/containers'

const props = defineProps<{ containers: Container[] }>()

const getContainerEmptyIN = (container: Container): DateTime | null => {
  return container.empty_in_gated_time?.isValid
    ? container.empty_in_gated_time
    : null
}
</script>

<template>
  <ContainersGroupedByDate
    :containers="props.containers"
    :get-date="getContainerEmptyIN"
    date-description="In-Gated"
    no-date-description="In-Gate Date Unknown"
    sort-direction="descending"
  />
</template>
