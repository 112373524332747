<script setup lang="ts">
import { computed } from 'vue'
import { MoveTypeFilter } from './types'
import DualsIcon from '~/components/icons/DualsIcon.vue'
import EmptyContainerIcon from '~/components/icons/EmptyContainerIcon.vue'
import LoadContainerIcon from '~/components/icons/LoadedContainerIcon.vue'

const selectedFilter = defineModel<MoveTypeFilter | null>()

const primaryOptions = [
  {
    label: 'Load Out',
    value: MoveTypeFilter.HasLoadOut,
  },
  {
    label: 'Single Load Out',
    value: MoveTypeFilter.LoadOutOnly,
  },
  {
    label: 'Empty In',
    value: MoveTypeFilter.HasEmptyIn,
  },
]

const otherOptions = [
  {
    label: 'Dual',
    value: MoveTypeFilter.Dual,
  },
  {
    label: 'Single Empty In',
    value: MoveTypeFilter.EmptyInOnly,
  },
  {
    label: 'Load In',
    value: MoveTypeFilter.HasLoadIn,
  },
  {
    label: 'Single Load In',
    value: MoveTypeFilter.LoadInOnly,
  },
  {
    label: 'Empty Out',
    value: MoveTypeFilter.HasEmptyOut,
  },
  {
    label: 'Single Empty Out',
    value: MoveTypeFilter.EmptyOutOnly,
  },
]
const otherMoveTypes = new Set(otherOptions.map((option) => option.value))

const localSelectedFilter = ref<MoveTypeFilter | null>(null)

watch(selectedFilter, (newValue) => {
  localSelectedFilter.value = newValue || null
})

const handleFilterChange = (value: MoveTypeFilter) => {
  if (localSelectedFilter.value === value) {
    localSelectedFilter.value = null
  } else {
    localSelectedFilter.value = value
  }
  selectedFilter.value = localSelectedFilter.value
}

const otherOptionsButtonType = computed(() => {
  return selectedFilter.value && otherMoveTypes.has(selectedFilter.value)
    ? 'primary'
    : 'default'
})

function isOptionSelected(value: MoveTypeFilter) {
  return selectedFilter.value === value
}
</script>

<template>
  <el-button-group>
    <el-button
      v-for="option in primaryOptions"
      :key="option.value"
      :type="isOptionSelected(option.value) ? 'primary' : 'default'"
      class="cursor-pointer"
      @click="handleFilterChange(option.value)"
    >
      <template #icon>
        <LoadContainerIcon v-if="option.value === MoveTypeFilter.HasLoadOut" />
        <EmptyContainerIcon v-if="option.value === MoveTypeFilter.HasEmptyIn" />
        <LoadContainerIcon v-if="option.value === MoveTypeFilter.LoadOutOnly" />
      </template>
      {{ option.label }}
    </el-button>
    <el-dropdown @command="handleFilterChange">
      <el-button style="margin-left: 1px" :type="otherOptionsButtonType">
        Other
        <i-mdi:chevron-down class="align-middle" />
      </el-button>
      <template #dropdown>
        <el-dropdown-menu>
          <el-dropdown-item
            v-for="option in otherOptions"
            :key="option.value"
            :command="option.value"
            class="other-move-type-option"
            :class="{
              selected: isOptionSelected(option.value),
            }"
          >
            <DualsIcon
              v-if="option.value === MoveTypeFilter.Dual"
              class="mr-2"
            />
            <LoadContainerIcon
              v-if="option.value === MoveTypeFilter.LoadOutOnly"
              class="mr-2"
            />
            <EmptyContainerIcon
              v-if="option.value === MoveTypeFilter.EmptyInOnly"
              class="mr-2"
            />
            {{ option.label }}
          </el-dropdown-item>
        </el-dropdown-menu>
      </template>
    </el-dropdown>
  </el-button-group>
</template>

<style lang="scss">
.other-move-type-option.selected {
  background-color: var(--el-color-primary);
  color: white;
}
</style>
