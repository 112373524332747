<script setup lang="ts">
const router = useRouter()

// Redirect to scheduled page
onMounted(() => {
  router.replace({ path: '/scheduled' })
})
</script>

<template>
  <!-- Historically we called the "Scheduled" page "Dispatch", so this is to catch bookmarks
   or people on the site and send them to the new page -->
  <div>Redirecting to Scheduled...</div>
</template>

<route lang="yaml">
meta:
  auth: true
name: dispatch
</route>
