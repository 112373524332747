<script lang="ts" setup>
import type { DateTime } from 'luxon'
import type { Container } from '~/models/containers'

const props = defineProps<{ containers: Container[] }>()

const getContainerLoadOut = (container: Container): DateTime | null => {
  if (
    container.import_out_gated_time &&
    container.import_out_gated_time.isValid
  ) {
    return container.import_out_gated_time
  }
  return null
}
</script>

<template>
  <ContainersGroupedByDate
    :containers="props.containers"
    :get-date="getContainerLoadOut"
    date-description="Out-Gated"
    no-date-description="Out-Gate Date Unknown"
    sort-direction="descending"
  />
</template>
