<script lang="ts" setup>
import { DateTime } from 'luxon'
import type { TerminalName } from '~/services/apiClient'

import { GATE_SCHEDULE_SOURCES } from '~/components/emptyReturnRules/terminals'
import { contentAreaHeightInjectionKey } from '~/constants'
import type { GateScheduleReading } from '~/models/gateSchedules'
import { useGateScheduleReadingsLatest } from '~/stores/gateScheduleReadingsLatest'
import { useGateScheduleReadingsHistory } from '~/stores/gateScheduleReadingHistory'

const props = defineProps<{ terminal: TerminalName }>()
const terminalRef = toRef(props, 'terminal')
const selectedHistoricalReading = ref(null as null | GateScheduleReading)

const gateScheduleReadingHistoryStore = useGateScheduleReadingsHistory()
const latestGateScheduleReading = useGateScheduleReadingsLatest()
const reading = computed(() => {
  if (selectedHistoricalReading.value) return selectedHistoricalReading.value
  return (
    latestGateScheduleReading.readings_by_terminal.get(props.terminal) || null
  )
})
const source = computed(() => {
  return GATE_SCHEDULE_SOURCES.find(
    (source) => source.terminal === props.terminal
  )!
})
const contentAreaHeight = inject(contentAreaHeightInjectionKey) as Ref<number>

const displayHeight = computed(() => {
  return contentAreaHeight.value - 230 + 'px'
})

function loadReadingsHistory() {
  gateScheduleReadingHistoryStore.load({
    terminalName: props.terminal,
    observedAfter: DateTime.now().minus({ days: 90 }),
  })
}
onMounted(loadReadingsHistory)
watch([terminalRef], () => {
  selectedHistoricalReading.value = null
  loadReadingsHistory()
})
function loadFilteredHistory(startDate: string, endDate: string) {
  gateScheduleReadingHistoryStore.load({
    terminalName: props.terminal,
    observedAfter: DateTime.fromISO(startDate).startOf('day'),
    observedBefore: DateTime.fromISO(endDate).endOf('day'),
  })
}
</script>

<template>
  <div class="flex my-4 w-full">
    <!-- List of versions (history) -->
    <div class="ml-3 mt-2 pr-10px mr-0 min-w-82">
      <h1 class="text-2xl font-semibold text-center mb-4 mr-4">
        <i-mdi:history class="align-middle mb-2px" />
        Gate Schedules History
      </h1>
      <DateFilter :on-apply="loadFilteredHistory" />
      <GateSchedulesReadingsHistory
        :selected-reading="selectedHistoricalReading"
        :style="{ height: displayHeight }"
        @reading-clicked="selectedHistoricalReading = $event"
      />
    </div>
    <!-- Screenshot/reading -->
    <GateSchedulesReadingDisplay
      v-if="reading"
      :reading="reading"
      :rules-source="source"
      :reading-is-historical="Boolean(selectedHistoricalReading)"
      :style="{ height: displayHeight }"
      class="flex-1"
    />
  </div>
</template>
