<script lang="ts" setup>
import { computed } from 'vue'
import ColoredBadge from '../display/ColoredBadge.vue'
import { useMakeLineAndTypeDescription } from '~/compositions/useMakeLineAndTypeDescription'
import { getTerminalLabel } from '~/constants'
import type { BasicContainer } from '~/models/containers'

const props = defineProps<{
  container: BasicContainer
}>()
const { makeLineAndTypeDescription } = useMakeLineAndTypeDescription()

// Compute the tag display
const tagDisplay = computed(() => {
  if (!props.container.tags || props.container.tags.length === 0) {
    return null
  }

  const firstTag = props.container.tags[0]
  const remainingTags = props.container.tags.length - 1

  return {
    text: remainingTags > 0 ? `${firstTag} +${remainingTags}` : firstTag,
    tooltip: props.container.tags.join(', '),
  }
})
</script>

<template>
  <div class="flex justify-between items-center w-full">
    <!-- Left -->
    <div class="flex items-center flex-1">
      <span class="container-number">
        {{ props.container.number }}
      </span>
      <span class="text-xs text-gray-400">
        • {{ makeLineAndTypeDescription(container) }}
      </span>
      <ColoredBadge
        v-if="tagDisplay"
        v-tooltip="tagDisplay.tooltip"
        type="info"
        size="small"
        effect="light"
        class="ml-2"
      >
        {{ tagDisplay.text }}
      </ColoredBadge>
    </div>
    <!-- Right -->
    <div class="ml-auto">
      <span>
        <template v-if="container.customer_name">
          {{ container.customer_name }}
        </template>
        <span
          v-if="container.import_out_gated_time"
          class="text-xs text-gray-400"
        >
          <template v-if="container.customer_name">•</template>
          out-gated
          {{ container.import_out_gated_time.toRelative() }}
          <span v-if="container.last_related_terminal">
            from
            <!-- In effect, this is always the "out gating" terminal if the container is
            in an "out gated" like state without an active appointment -->
            {{ getTerminalLabel(container.last_related_terminal) }}
          </span>
        </span>
      </span>
    </div>
  </div>
</template>

<style scoped>
.container-number {
  min-width: 102px;
  display: inline-block;
  text-align: left;
}
</style>
