<script lang="ts" setup>
import { computed, toRef } from 'vue'
import type { DateTime } from 'luxon'
import StickyHeaderVirtualScroller from '~/components/display/virtual_scrolling/StickyHeaderVirtualScroller.vue'
import type { Container } from '~/models/containers'

const props = defineProps<{
  containers: Container[]
  getDate: (container: Container) => DateTime | null
  dateDescription: string
  noDateDescription: string
  sortDirection: 'ascending' | 'descending'
}>()

const containers = toRef(props, 'containers')

const containersWithDate = computed(() => {
  return containers.value
    .map((container) => {
      return {
        id: container.container_number,
        container,
        date: props.getDate(container) ?? undefined,
      }
    })
    .sort((a, b) => {
      if (!a.date && !b.date) return 0
      if (!a.date) return 1
      if (!b.date) return -1
      return props.sortDirection === 'ascending'
        ? a.date.toMillis() - b.date.toMillis()
        : b.date.toMillis() - a.date.toMillis()
    })
})

const getHeaderId = (item: {
  id: string
  container: Container
  date: DateTime | undefined
}) => {
  return item.date
    ? `date-${item.date.year}-${item.date.month}-${item.date.day}`
    : 'unknown'
}

const countContainers = computed(() => {
  const count: Record<string, number> = {}
  containersWithDate.value.forEach((item) => {
    const headerId = getHeaderId(item)
    count[headerId] = (count[headerId] || 0) + 1
  })
  return count
})

const getContainerCount = (headerId: string) => {
  return countContainers.value[headerId] || 0
}
</script>

<template>
  <StickyHeaderVirtualScroller
    :items="containersWithDate"
    page-mode
    :buffer="600"
    class="h-full"
    :header-height="28"
    :get-header-id="getHeaderId"
    :get-row-height="(item) => item.container.rowHeight"
  >
    <template #default="{ item }">
      <ContainerRow :container="item.container" />
    </template>

    <template #header="{ item }">
      <CommonGroupHeader
        :date="item.date || null"
        :title="props.dateDescription"
        :container-count="getContainerCount(getHeaderId(item))"
        :no-date-description="props.noDateDescription"
      />
    </template>
  </StickyHeaderVirtualScroller>
</template>
