<script lang="ts" setup>
import { DateTime } from 'luxon'
import { ref } from 'vue'

const props = defineProps<{
  onApply: (startDate: string, endDate: string) => void
}>()
const minDate = DateTime.now().minus({ months: 24 }).toFormat('yyyy-MM-dd')
const maxDate = DateTime.now().toFormat('yyyy-MM-dd')
const startDate = ref<string>(
  DateTime.now().minus({ days: 90 }).toFormat('yyyy-MM-dd')
)
const endDate = ref<string>(maxDate)

function applyFilter() {
  props.onApply(startDate.value, endDate.value)
}

function resetFilter() {
  startDate.value = DateTime.now().minus({ days: 90 }).toFormat('yyyy-MM-dd')
  endDate.value = maxDate
  applyFilter()
}

function checkEndDateNotGreaterThanMaxDate(endDateValue: string) {
  if (endDateValue > maxDate) {
    endDate.value = maxDate
  } else {
    endDate.value = endDateValue
  }
}
function onStartDateSelect(date: string) {
  startDate.value = date
  const start = DateTime.fromISO(startDate.value)
  const end = DateTime.fromISO(endDate.value)
  if (Math.abs(end.diff(start, 'days').days) > 90) {
    const endDateValue = start.plus({ days: 90 }).toFormat('yyyy-MM-dd')
    checkEndDateNotGreaterThanMaxDate(endDateValue)
  } else if (start > end) {
    const endDateValue = start.plus({ days: 90 }).toFormat('yyyy-MM-dd')
    checkEndDateNotGreaterThanMaxDate(endDateValue)
  }
}

function onEndDateSelect(date: string) {
  endDate.value = date
  const start = DateTime.fromISO(startDate.value)
  const end = DateTime.fromISO(endDate.value)
  if (Math.abs(start.diff(end, 'days').days) > 90 || start > end) {
    startDate.value = end.minus({ days: 90 }).toFormat('yyyy-MM-dd')
  }
}
</script>

<template>
  <div class="py-2">
    <h2 class="font-bold text-sm">History Date Range Filter (Max 90 days)</h2>
    <div class="flex gap-2">
      <el-input
        v-model="startDate"
        type="date"
        placeholder="Start Date"
        :max="maxDate"
        :min="minDate"
        @change="onStartDateSelect($event)"
      />
      <el-input
        v-model="endDate"
        :min="minDate"
        type="date"
        placeholder="End Date"
        :max="maxDate"
        @change="onEndDateSelect($event)"
      />
    </div>
    <div class="flex gap-2 mt-3">
      <el-button type="primary" class="flex-1" @click="applyFilter">
        Apply
      </el-button>
      <el-button type="primary" class="flex-1" @click="resetFilter">
        Reset Filter
      </el-button>
    </div>
  </div>
</template>
