<script lang="ts" setup>
import type { DateTime } from 'luxon'
import type { Container } from '~/models/containers'

const props = defineProps<{ containers: Container[] }>()

const getContainerLFD = (container: Container): DateTime | null => {
  return container.last_free_day?.isValid ? container.last_free_day : null
}
</script>

<template>
  <ContainersGroupedByDate
    :containers="props.containers"
    :get-date="getContainerLFD"
    date-description="LFD"
    no-date-description="LFD Unknown"
    sort-direction="ascending"
  />
</template>
