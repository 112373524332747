<script lang="ts" setup>
import { computed } from 'vue'
import type { DateTime } from 'luxon'

const props = defineProps<{
  date: DateTime | null
  title: string
  containerCount: number
  noDateDescription: string
}>()

const formattedDate = computed(() => {
  return props.date ? props.date.toFormat('ccc L/d') : props.noDateDescription
})
</script>

<template>
  <BaseAppointmentHeader :is-sub-header="false">
    <template #left>
      <span v-if="formattedDate.includes('Unknown')">{{ formattedDate }}</span>
      <span v-else>{{ props.title }} {{ formattedDate }}</span>
    </template>
    <template #right>
      {{ props.containerCount }} container{{
        props.containerCount > 1 ? 's' : ''
      }}
    </template>
  </BaseAppointmentHeader>
</template>
