<script lang="ts" setup>
import { DateTime } from 'luxon'
import {
  AppointmentsApi,
  GETFileExportFileTypeEnum,
} from '~/services/apiClient'

const props = defineProps<{
  appointmentIds: number[]
}>()

const downloading = ref(false)
function download(fileType: GETFileExportFileTypeEnum, ids: number[]) {
  downloading.value = true
  const now = DateTime.now()
  const fileName = `appointments ${now.toFormat('yyyy-MM-dd HH_mm_ss')}.${fileType}`
  const api = new AppointmentsApi()
  api
    .fileExportAppointmentsFileExportPost(
      {
        file_type: fileType,
        to_trinium: false,
        appointment_ids: ids,
      },
      { responseType: 'blob' }
    )
    .then((resp) => {
      if (resp.status === 200) {
        const blob = new Blob([resp.data], {
          type: resp.headers['content-type'],
        })
        const url = window.URL.createObjectURL(blob)
        const link = document.createElement('a')
        link.href = url
        link.setAttribute('download', fileName)
        document.body.appendChild(link)
        link.click()
      }
    })
    .finally(() => {
      downloading.value = false
    })
}
</script>

<template>
  <span>
    <el-button
      class="mr-2"
      :disabled="props.appointmentIds.length === 0"
      :loading="downloading"
      @click="download(GETFileExportFileTypeEnum.Csv, props.appointmentIds)"
    >
      <i-mdi:download />
      Download as CSV
    </el-button>
    <el-button
      class="mr-2"
      style="margin-left: 0"
      :disabled="props.appointmentIds.length === 0"
      :loading="downloading"
      @click="download(GETFileExportFileTypeEnum.Xlsx, props.appointmentIds)"
    >
      <i-mdi:download />
      Download as Excel
      <i-file-icons:microsoft-excel class="ml-1" /> </el-button
  ></span>
</template>
