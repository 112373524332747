<script lang="ts" setup>
import { useRoute } from 'vue-router'
import { useUserStore } from '~/stores/user'
import HeaderLink from '~/components/navigation/HeaderLink.vue'
import { useCurrentOrgStore } from '~/stores/currentOrg'

const userStore = useUserStore()
const router = useRouter()
const route = useRoute()
const { selectOrgFromURL } = useCurrentOrgStore()

onMounted(selectOrgFromURL)
function reloadPage() {
  router.go(0)
}
</script>

<template>
  <el-header
    class="responsive-header !px-3"
    style="height: initial; background-color: rgb(14, 98, 182)"
  >
    <div
      class="flex justify-between items-center w-full px-0 text-dark-600 py-3"
    >
      <div
        class="flex items-center text-gray-200"
        style="font-family: 'Russo One', Roboto, sans-serif"
      >
        <img
          src="../../assets/logo_outline.svg"
          alt=""
          style="height: 35px; margin-top: -5px"
          class="mb-1"
        />
        <h1
          class="ml-1 font-bold italic align-top mr-4 lg:mr-14 mt-3px"
          style="font-size: 32px; line-height: 0.8em"
        >
          DRAY DOG
        </h1>
        <HeaderLink
          v-if="userStore.loggedIn && !userStore.isTerminalOrg"
          route-name="containers"
        >
          <EmptyContainerIcon
            class="mr-1 lg:ml-4"
            style="position: relative; top: 5px"
          />
          Containers
        </HeaderLink>
        <HeaderLink route-name="cameras-home">
          <i-ic:baseline-live-tv
            class="mr-1 lg:ml-6"
            style="position: relative; top: 3px; height: 22px"
          />
          Port Cameras
        </HeaderLink>
      </div>
      <div v-if="userStore.loggedIn">
        <div v-if="userStore.is_internal_user" class="inline-block">
          <el-switch
            v-model="userStore.demo_mode"
            data-testid="demo-mode-switch"
            inline-prompt
            active-text="Demo Mode"
            inactive-text="Normal Mode"
            style="
              --el-switch-on-color: #13ce66;
              --el-switch-off-color: #ff4949;
              margin-right: 10px;
            "
            @change="reloadPage()"
          />
        </div>
        <OrganizationDropdown
          v-if="userStore.userInfo"
          :user-info="userStore.userInfo"
          data-testid="organization-dropdown"
        />
      </div>
      <router-link
        v-else-if="route.name !== 'login'"
        to="/login/"
        data-testid="login_button"
      >
        <!-- TODO: Make this the primary color -->
        <el-button type="primary" size="default" color="#043960">
          Login
        </el-button>
      </router-link>
    </div>
  </el-header>
</template>

<style scoped>
.header {
  box-shadow: var(--el-box-shadow-light);
}
</style>
