<script lang="ts" setup>
import type { DateTime } from 'luxon'
import { computed, toRef } from 'vue'
import type { Container } from '~/models/containers'
import type { VesselETA } from '~/models/vesselETA'
import { useVesselETAsStore } from '~/stores/vesselETAs'

const props = defineProps<{ containers: Container[] }>()
const vesselETAStore = useVesselETAsStore()
const containers = toRef(props, 'containers')

const vesselETALookup = computed(() => {
  const lookup = new Map<string, VesselETA | null>()
  containers.value.forEach((container) => {
    if (container.matched_vessel) {
      const key = String(container.matched_vessel.id) + container.terminal
      lookup.set(
        key,
        vesselETAStore.getVesselETA(
          container.matched_vessel.id,
          container.terminal
        )
      )
    }
  })
  return lookup
})

const getContainerETA = (container: Container): DateTime | null => {
  return container.matched_vessel
    ? (vesselETALookup.value.get(
        String(container.matched_vessel.id) + container.terminal
      )?.eta ?? null)
    : null
}
</script>

<template>
  <ContainersGroupedByDate
    :containers="props.containers"
    :get-date="getContainerETA"
    date-description="ETA"
    no-date-description="ETA Unknown"
    sort-direction="ascending"
  />
</template>
