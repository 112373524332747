<script lang="ts" setup>
import { useRouteQuery } from '@vueuse/router'
import { DateTime } from 'luxon'
import { isValidTerminalName } from '~/constants'
import { TerminalName } from '~/services/apiClient'
import { useGateSchedulesStore } from '~/stores/gateSchedules'

useHead({
  title: 'Dray Dog - Gate Schedules',
})
const gateSchedulesStore = useGateSchedulesStore()

onMounted(gateSchedulesStore.load)
enum ViewOption {
  AllSchedules = 'All Schedules',
  ByTerminal = 'Terminal',
}
const terminalQuery = useRouteQuery<string | undefined>('terminal')

const viewQuery = useRouteQuery<string | undefined>('view')
const thisWeeksDates = computed((): DateTime[] => {
  const startOfWeek = DateTime.now().startOf('week')
  const dates = []
  for (let i = 0; i < 7; i++) {
    dates.push(startOfWeek.plus({ days: i }))
  }
  return dates
})
const nextWeeksDates = computed((): DateTime[] => {
  const startOfWeek = DateTime.now().startOf('week')
  const dates = []
  for (let i = 7; i < 14; i++) {
    dates.push(startOfWeek.plus({ days: i }))
  }
  return dates
})

function weekTitle(dates: DateTime[]) {
  const start = dates[0].toFormat('MMMM d')
  const end = dates[dates.length - 1].toFormat('d')
  return `Week of ${start} - ${end}`
}

const now = ref(DateTime.now())
onMounted(() => {
  // Update once a minute
  setInterval(() => {
    now.value = DateTime.now()
  }, 60000)
})

function isValidViewOption(value: any): value is ViewOption {
  return Object.values(ViewOption).includes(value)
}
// Computed properties
const view = computed(() => {
  if (isValidViewOption(viewQuery.value)) {
    return viewQuery.value
  }
  return ViewOption.AllSchedules
})

const terminal = computed(() => {
  if (isValidTerminalName(terminalQuery.value)) {
    return terminalQuery.value
  }
})

function setView(value: string | boolean | number | undefined) {
  if (typeof value !== 'string') {
    throw new TypeError(
      `Value must be a string but got ${typeof value} (${value})`
    )
  }
  viewQuery.value = value
}

function setTerminal(value: string | boolean | number) {
  if (typeof value !== 'string') {
    throw new TypeError(
      `Value must be a string but got ${typeof value} (${value})`
    )
  }
  terminalQuery.value = value
}

watch(
  [view, terminal],
  () => {
    if (view.value === ViewOption.ByTerminal) {
      if (!terminal.value) {
        terminalQuery.value = TerminalName.Apm
      }
    } else {
      terminalQuery.value = undefined
    }
  },
  { immediate: true }
)
</script>

<template>
  <el-card class="mb-6px">
    <el-form class="flex err-form-controls">
      <!-- View options -->
      <el-form-item label="View by:" class="mr-8 !mb-0">
        <el-radio-group
          :model-value="view"
          size="large"
          @update:model-value="setView($event)"
        >
          <el-radio-button :value="ViewOption.AllSchedules">
            All Schedules
          </el-radio-button>
          <el-radio-button :value="ViewOption.ByTerminal">
            <i-mdi:camera-outline class="align-middle -mt-3px" />Terminals
          </el-radio-button>
        </el-radio-group>
      </el-form-item>

      <div v-if="view === ViewOption.ByTerminal">
        <el-form-item label="Terminal:">
          <GateSchedulesTerminalRadioGroup
            :model-value="terminal"
            @update:model-value="setTerminal($event)"
          />
        </el-form-item>
      </div>
    </el-form>
  </el-card>
  <el-card>
    <div v-if="view === ViewOption.AllSchedules">
      <el-card>
        <h2 class="text-center text-xl font-bold">
          {{ weekTitle(thisWeeksDates) }}
        </h2>
        <div v-loading="gateSchedulesStore.loading">
          <GateScheduleTable
            :schedules="gateSchedulesStore.gate_schedules"
            :dates="thisWeeksDates"
            :now="now"
          />
        </div>
        <div class="mt-1">
          <el-alert type="info" :closable="false">
            Click an entry to view the relevant screenshot
          </el-alert>
        </div>
      </el-card>
      <!-- Next weeks gate schedules -->
      <el-card class="mt-2">
        <h2 class="text-center text-xl font-bold">
          {{ weekTitle(nextWeeksDates) }}
        </h2>
        <div v-loading="gateSchedulesStore.loading">
          <GateScheduleTable
            :schedules="gateSchedulesStore.gate_schedules"
            :dates="nextWeeksDates"
            :now="now"
          />
        </div>
        <div class="mt-1">
          <el-alert type="info" :closable="false">
            Click an entry to view the relevant screenshot
          </el-alert>
        </div>
      </el-card>
    </div>
    <div v-else-if="view === ViewOption.ByTerminal">
      <GateScheduleReadingDisplayWrapper
        v-if="terminal"
        :terminal="terminal"
      ></GateScheduleReadingDisplayWrapper>
    </div>
  </el-card>
</template>

<route lang="yaml">
meta:
  layout: default
  auth: true
name: gate-schedules
</route>
