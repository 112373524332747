<script lang="ts" setup>
const props = defineProps<{
  tooltip?: string
  isSubHeader: boolean
}>()
</script>

<template>
  <div
    class="appointment-group-header"
    :class="{ subheader: props.isSubHeader }"
  >
    <div v-tooltip="tooltip">
      <slot name="left" />
    </div>
    <div class="font-normal flex items-baseline">
      <slot name="right" />
    </div>
  </div>
</template>

<style lang="scss" scoped>
.appointment-group-header {
  height: var(--appointment-group-header-height);
  @apply bg-gray-300 text-sm flex items-center justify-between px-2 text-sm text-gray-600 font-bold relative;
  z-index: 7;
}
.appointment-group-header.subheader {
  @apply bg-gray-200 font-semibold;
}
</style>
