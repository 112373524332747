<script setup lang="ts">
import { reactive, ref } from 'vue'
import type { GETToken } from '~/services/apiClient'
import { UsersApi } from '~/services/apiClient'
import CenteredCard from '~/components/display/CenteredCard.vue'

const emit = defineEmits<{
  (e: 'login', token: GETToken): void
}>()

const formRef = ref()
const form = reactive({
  email: '',
  password: '',
})

const usersApi = new UsersApi()
const loggingIn = ref(false)

async function onLogin() {
  loggingIn.value = true
  try {
    const resp = await usersApi.getTokenUsersTokenPost({
      email: form.email,
      password: form.password,
    })
    emit('login', resp.data)
  } finally {
    loggingIn.value = false
  }
}
</script>

<template>
  <CenteredCard>
    <template #title>
      <div
        style="font-family: 'Russo One', Roboto, sans-serif; font-size: 30px"
        class="-mb-2"
      >
        <img
          src="../../assets/logo_outline.svg"
          alt=""
          style="height: 30px; margin-top: -5px"
          class="mb-1 inline-block"
        />
        Login
      </div>
    </template>
    <el-form
      ref="formRef"
      v-model="form"
      label-width="84px"
      @submit.prevent="onLogin"
      @keyup.enter="onLogin"
    >
      <el-form-item label="Email:">
        <el-input v-model="form.email" name="email"></el-input>
      </el-form-item>
      <el-form-item label="Password:">
        <el-input
          v-model="form.password"
          type="password"
          name="password"
          show-password
        ></el-input>
      </el-form-item>
      <el-button
        :loading="loggingIn"
        type="primary"
        class="w-full"
        @click="onLogin"
      >
        Login
      </el-button>
    </el-form>
    <div class="text-center mt-2">
      <slot name="extra" />
    </div>
  </CenteredCard>
</template>
