<script lang="ts" setup>
/* Displays a table of empty return locations, optionally filtering by shipping line,
container type */
import { computed } from 'vue'
import type { DateTime } from 'luxon'
import type {
  EmptyAppointmentSlotsLoadedEvent,
  TerminalName,
} from '~/services/apiClient'

import { EventName, Shift } from '~/services/apiClient'
import type {
  CategoryKey,
  CategoryKeyWithTimestamps,
} from '~/stores/returnRulesLatest'
import { useReturnRulesLatest } from '~/stores/returnRulesLatest'
import { useEmptyAppointmentSlotsStore } from '~/stores/emptyAppointmentSlots'
import type { SoketiEvent } from '#compositions/useSoketi'
import { useSoketi } from '#compositions/useSoketi'
import { useAppointmentSlotEvents } from '~/compositions/soketi/useAppointmentSlotEvents'
import type { AppointmentWithContainerInfo } from '~/models/appointmentWithContainerInfo'
import { useReturnRulesAndFilters } from '~/compositions/useReturnRulesAndFilters'
import useStartOfToday from '~/compositions/useStartOfToday'
import { useUserStore } from '~/stores/user'

// Properties
const props = defineProps<{
  searchedContainerNumber: string | null
  existingSingleEmptyAppointments: AppointmentWithContainerInfo[]
}>()
const {
  bind: bindWebsocketEvent,
  unbind: unbindWebsocketEvent,
  isBound: isWebsocketEventBound,
  eventHandlers: websocketEventHandlers,
} = useSoketi()
// Refs
const userStore = useUserStore()
// Stores + compositions
const apptSlotEventHelpers = useAppointmentSlotEvents()
const latestReturnRulesStore = useReturnRulesLatest()
const emptyAppointmentSlotsStore = useEmptyAppointmentSlotsStore()
const lookupReturnRule = latestReturnRulesStore.lookupRule
const loadingReturnRules = toRef(latestReturnRulesStore, 'loading')
const datesWithRules = toRef(latestReturnRulesStore, 'datesWithRules')
const {
  applicableReturnRuleCategoryKeys,
  applicableNotClosedCategoryKeys,
  shippingLine,
  containerType,
  terminal,
} = useReturnRulesAndFilters()
// Computed properties
const loading = computed(() => {
  const loadingSlotPreRequisites =
    !apptSlotEventHelpers.prerequisitesLoaded.value
  return loadingReturnRules.value || loadingSlotPreRequisites
})
const showShippingLine = computed(() => !shippingLine.value)
const showContainerType = computed(() => !containerType.value)
const showTerminal = computed(() => !terminal.value)
const startOfToday = useStartOfToday()

const dates = computed(() => {
  // Find the max date from both sources
  let maxDate: DateTime | null = null

  // Check return rules dates
  if (datesWithRules.value.length > 0) {
    maxDate = datesWithRules.value.reduce(
      (max, curr) => (curr > max ? curr : max),
      datesWithRules.value[0]
    )
  }

  // Check empty appointment slots dates
  for (const category of applicableReturnRuleCategoryKeys.value) {
    const slotsReading = emptyAppointmentSlotsStore.getSlotReading(category)
    if (slotsReading?.slots.length) {
      const slotsMaxDate = slotsReading.slots.reduce(
        (max, slot) => (slot.window_start > max ? slot.window_start : max),
        slotsReading.slots[0].window_start
      )
      maxDate = maxDate
        ? slotsMaxDate > maxDate
          ? slotsMaxDate
          : maxDate
        : slotsMaxDate
    }
  }

  // If no dates found, return empty array
  if (!maxDate) {
    return []
  }

  // Generate all dates from today to max date
  const allDatesFilled: DateTime[] = []
  for (
    let currentDate = startOfToday.value;
    currentDate <= maxDate;
    currentDate = currentDate.plus({ days: 1 })
  ) {
    allDatesFilled.push(currentDate)
  }

  return allDatesFilled
})
const existingSingleEmptyAppointmentsByTerminalAndShift = ref(
  new Map<TerminalName, Map<string, AppointmentWithContainerInfo[]>>()
)
const containerCountsByTerminal = ref(new Map<TerminalName, number>())

// Functions
function handleExistingSingleEmptyAppointmentsChange() {
  const lookup = new Map<
    TerminalName,
    Map<string, AppointmentWithContainerInfo[]>
  >()
  const counts = new Map<TerminalName, number>()
  for (const appointmentInfo of props.existingSingleEmptyAppointments) {
    const appointment = appointmentInfo.appointment
    if (appointment.window_start < startOfToday.value) {
      continue
    }
    // Ignore empty appointments that are part of a dual transaction
    if (appointment.linked_appointments?.length) {
      continue
    }
    // Add to lookups
    const terminalLookup =
      lookup.get(appointment.terminal) ??
      new Map<string, AppointmentWithContainerInfo[]>()
    lookup.set(appointment.terminal, terminalLookup)
    const appointmentInfos =
      terminalLookup.get(appointment.scheduledShift) ?? []
    appointmentInfos.push(appointmentInfo)
    terminalLookup.set(appointment.scheduledShift, appointmentInfos)
    counts.set(
      appointment.terminal,
      (counts.get(appointment.terminal) ?? 0) + 1
    )
  }
  existingSingleEmptyAppointmentsByTerminalAndShift.value = lookup
  containerCountsByTerminal.value = counts
}

function handleEmptyAppointmentSlotEvent(event: SoketiEvent) {
  emptyAppointmentSlotsStore.handleEvent(
    event as EmptyAppointmentSlotsLoadedEvent
  )
}
async function updateEmptyAppointmentSlotEventHandlers(
  categories: CategoryKeyWithTimestamps[]
) {
  await apptSlotEventHelpers.waitForPreRequisitesToLoadIfNeeded()
  // not showing slots to terminal orgs for now
  if (userStore.isTerminalOrg) {
    return
  }
  // Calculate all channel names
  const channelNames = new Set<string>()
  for (const key of categories) {
    const channelName = apptSlotEventHelpers.getAppointmentSlotChannelName(
      key.terminal,
      key.shippingLine,
      key.containerType
    )
    channelNames.add(channelName)
  }
  // Add missing categories
  for (const channelName of channelNames) {
    if (
      !isWebsocketEventBound(channelName, EventName.EmptyAppointmentSlotsLoaded)
    ) {
      // console.log(`binding ${channelName}`)
      bindWebsocketEvent(
        channelName,
        EventName.EmptyAppointmentSlotsLoaded,
        handleEmptyAppointmentSlotEvent
      )
    }
  }
  // Remove categories that are no longer needed
  for (const channelName of websocketEventHandlers.value.keys()) {
    if (
      channelName.startsWith('private-empty-appointment-slots-') &&
      !channelNames.has(channelName)
    ) {
      // console.log(`unbinding ${channelName}`)
      unbindWebsocketEvent(
        channelName,
        EventName.EmptyAppointmentSlotsLoaded,
        handleEmptyAppointmentSlotEvent
      )
    }
  }
}
// Watchers
watch(
  () => props.existingSingleEmptyAppointments,
  handleExistingSingleEmptyAppointmentsChange,
  { immediate: true }
)

function makeCategoryTypeKey(category: CategoryKey): string {
  return `${category.terminal}-${category.shippingLine}-${category.containerType}`
}

watch(
  applicableNotClosedCategoryKeys,
  async (newValues, oldValues) => {
    // Load slots for any categories that were not previously loaded
    const oldValueKeys = new Set<string>()
    if (oldValues) {
      oldValues.forEach((x) => {
        oldValueKeys.add(makeCategoryTypeKey(x))
      })
    }
    const categoriesToLoad: CategoryKey[] = []
    newValues.forEach((x) => {
      const key = makeCategoryTypeKey(x)
      if (!oldValueKeys.has(key)) {
        categoriesToLoad.push(x)
      }
    })
    if (categoriesToLoad.length > 0) {
      await apptSlotEventHelpers.waitForPreRequisitesToLoadIfNeeded()
    }
    if (!userStore.isTerminalOrg) {
      for (const category of categoriesToLoad) {
        emptyAppointmentSlotsStore.load(category)
      }
    }
  },
  { immediate: true }
)
onMounted(() => {
  apptSlotEventHelpers.loadPreRequisites()
  updateEmptyAppointmentSlotEventHandlers([])
})
// Will be regenerated many times even if not changed
watch(applicableReturnRuleCategoryKeys, updateEmptyAppointmentSlotEventHandlers)

const hasZeroRules = computed(
  () => applicableReturnRuleCategoryKeys.value.length === 0
)
</script>

<template>
  <div
    v-if="loading"
    class="flex justify-center items-center h-30vh text-xl text-gray-600"
  >
    Loading...
  </div>

  <div
    v-else-if="hasZeroRules"
    class="bg-gray-100 border border-gray-300 p-4 rounded-md text-gray-600 text-xl"
  >
    <p class="font-medium">
      <i-mdi:alert-circle class="text-yellow-500 align-middle mr-2" />
      No rules found.
    </p>
    <p>Please try selecting another shipping line or container type.</p>
    <!-- Add a message and button -->
    <p class="mt-2">
      If you think this is wrong, please send us feedback
      <span class="emoji" style="font-size: 40px">&#x1F449;</span>
    </p>
  </div>

  <div v-else class="table-wrapper">
    <table class="err-table">
      <!-- Header -->
      <thead>
        <tr>
          <!-- Column headers -->
          <th v-if="showShippingLine" class="label fixed-col" rowspan="2">
            <VesselIcon class="align-middle" />
            Line
          </th>
          <th v-if="showTerminal" class="label fixed-col" rowspan="2">
            <i-mdi:pier-crane class="align-text-bottom" />
            Terminal
          </th>
          <th v-if="showContainerType" class="label fixed-col" rowspan="2">
            <EmptyContainerIcon class="align-middle" />
            Type
          </th>
          <!-- Date headers -->
          <th
            v-for="date of dates"
            :key="date.toISODate()"
            colspan="2"
            class="relative"
          >
            <span> {{ date.toFormat('cccc M/d') }}</span>
          </th>
        </tr>
        <!-- Shifts -->
        <tr class="text-sm">
          <template v-for="_ of dates" :key="_.toISODate()">
            <th>
              <div class="relative">
                <span class="text-xs">1st Shift</span>
              </div>
            </th>
            <th class="text-xs">2nd Shift</th>
          </template>
        </tr>
      </thead>
      <tbody>
        <!-- Terminals/rules rows -->
        <tr
          v-for="(row, index) in applicableReturnRuleCategoryKeys"
          :key="`row ${row.shippingLine}-${row.containerType}-${row.terminal}`"
          :aria-label="`${row.shippingLine} ${row.containerType} ${row.terminal}`"
        >
          <!-- Labels -->
          <ReturnRulesRowHeaders
            :key="`${row.shippingLine}-${row.containerType}-${
              row.terminal
            }-${row.lastChecked.toISO()}`"
            :row="row"
            :previous-row="
              index > 0 ? applicableReturnRuleCategoryKeys[index - 1] : null
            "
            :container-count="containerCountsByTerminal.get(row.terminal) ?? 0"
            :show-shipping-line="showShippingLine"
            :show-terminal="showTerminal"
            :show-container-type="showContainerType"
          />
          <!-- Results/body -->
          <template v-for="date of dates" :key="date.toISODate()">
            <template
              v-for="shift of [Shift.First, Shift.Second]"
              :key="`${date.toISODate()}_${shift}`"
            >
              <ReturnRulesTableTdWithPopover
                :return-rule="
                  lookupReturnRule(
                    row.shippingLine,
                    row.containerType,
                    row.terminal,
                    date,
                    shift
                  )
                "
                :shipping-line="row.shippingLine"
                :container-type="row.containerType"
                :single-empty-appointments-lookup="
                  existingSingleEmptyAppointmentsByTerminalAndShift
                "
                :terminal="row.terminal"
                :date="date"
                :shift="shift"
                :searched-container-number="props.searchedContainerNumber"
              />
            </template>
          </template>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<style lang="scss">
$label-width: 60px;

.table-wrapper {
  overflow-x: auto;
  border-radius: 5px;
}

table.err-table {
  table-layout: fixed;
  $border-radius: 3px;
  $interior-border: 1px solid theme('colors.gray.400');
  $strong-header-color: theme('colors.gray.600');
  border-collapse: collapse;
  border-radius: $border-radius;
  border-style: hidden; /* hide standard table (collapsed) border */
  > thead,
  > tbody > {
    tr > th.label {
      border-right: $interior-border;
    }
  }
  > thead {
    @apply text-base;
    color: $strong-header-color;
    tr th {
      @apply py-2;
    }
    tr:first-child {
      th:nth-child(even) {
        @apply bg-gray-100;
      }
      th:nth-child(odd) {
        @apply bg-gray-50;
      }
    }
    tr:last-child {
      border-top: $interior-border;
      th {
        // Striping affect
        &:nth-child(odd) {
          @apply bg-gray-100;
        }
      }
    }
    th.label {
      @apply text-base;
    }
    .header-label {
      @apply absolute left-0 top-0 h-full flex items-center justify-center pl-2;
      width: $label-width;
    }
    .label,
    .header-label {
      @apply text-gray-500;
    }
  }
  > tbody > tr > {
    th {
      @apply text-gray-500;
      width: 60px;
      .terminal-name-label {
        color: $strong-header-color;
      }
    }
    td,
    th {
      border-top: $interior-border;
      min-width: 9vw;
    }
  }
  .fixed-col {
    position: sticky;
    left: 0;
    z-index: 1;
    background-color: white;
    border-right: 1px solid #e0e0e0 !important;
    box-shadow: 2px 0 5px -2px rgba(0, 0, 0, 0.4);
  }
}
</style>
